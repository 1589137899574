<template>
    <div class="bg-white box-table-data-sub">
                                   
        <table class="table table-bordered">
            <caption>Sensitivity analysis</caption>
            <thead>
                <tr>
                    <td style="width:12%">Sensitivity analysis</td>
                    <td style="width:9%">Line, subgroup</td>
                    <td style="width:16.5%">Comparator (Latest undominated comparator)</td>
                    <td style="width:16.5%">Parameter (change)/Scenario</td>
                    <td style="width:7.5%">Cost savings</td>
                    <td style="width:7.5%">Total cost</td>
                    <td style="width:7.5%">Net monetary benefit</td>
                    <td style="width:7.5%">Net monetary loss</td>
                    <td style="width:10%">Comment</td>
                </tr>
            </thead>
            <tbody>
                <template v-for="(Mitem, Mkey) in data.economic_analysis" :key="Mkey">
                <template v-if="Mitem.economic_analysis_result">
                    <template v-for="(Mitem2, Mkey2) in Mitem.economic_analysis_result" :key="Mkey2">
                        <template v-if="Mitem2.sensitivity">
                        <tr v-for="(item, key) in Mitem2.sensitivity" :key="key">
                            <td>{{ checkEmpty(Mitem2.source_of_analysis) }}</td>
                            <td>{{ checkEmpty(item.line_subgroup) }}</td>
                            <td>{{ checkEmpty(item.comparator) }}</td>
                            <td>{{ checkEmpty(item.parameter_scenario) }}</td>
                            <td>{{ checkEmpty(item.cost_savings) }}</td>
                            <td>{{ checkEmpty(item.total_cost) }}</td>                                           
                            <td>{{ checkEmpty(item.net_monetary_benefit) }}</td>
                            <td>{{ checkEmpty(item.net_monetary_loss) }}</td>
                            <td>{{ checkEmpty(item.comment) }}</td>
                        </tr>
                        </template>
                    </template>
                </template>
                </template>
                                                   
            </tbody>
        </table>
        
        <div class="button-fixed">
            <button type="button" class="btn">Sensitivity analysis</button>
        </div>
    </div>  
</template>

<script>
import { checkEmpty } from '@/utils'
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    setup(){
        return {checkEmpty}
    }
}
</script>
