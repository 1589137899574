<template>
    <div class="bg-white box-table-sub">
        <template v-for="(item, key)  in data.economic_analysis" :key="key">   
            <table class="table table-bordered" >
                <caption>Table Discussion</caption>
                <tbody>
                    <tr v-if="item.discussion_study_finding && (globalAccess.includes('study_finding') || !hasGlobalAccess)">
                        <td>Study finding</td>
                        <td v-html="item.discussion_study_finding"></td>
                    </tr>
                    <tr v-if="item.discussion_assumptions && (globalAccess.includes('assumptions') || !hasGlobalAccess)">
                        <td>Assumptions</td>
                        <td v-html="item.discussion_assumptions"></td>
                    </tr>
                    <tr v-if="item.discussion_comparision_with_other_studies && (globalAccess.includes('comparison_studies') || !hasGlobalAccess)">
                        <td>Comparison with other studies</td>
                        <td v-html="item.discussion_comparision_with_other_studies"></td>
                    </tr>
                    <tr v-if="item.discussion_validation && (globalAccess.includes('validation') || !hasGlobalAccess)">
                        <td>Validation</td>
                        <td v-html="item.discussion_validation"></td>
                    </tr>
                    <tr v-if="item.discussion_generalizability_transferability && (globalAccess.includes('transferability') || !hasGlobalAccess)">
                        <td>Transferability</td>
                        <td v-html="item.discussion_generalizability_transferability"></td>
                    </tr>
                    <tr v-if="item.discussion_extrapolation && (globalAccess.includes('extrapolation') || !hasGlobalAccess)">
                        <td>Extrapolation</td>
                        <td v-html="item.discussion_extrapolation"></td>
                    </tr>
                    <tr v-if="item.discussion_conclusion && (globalAccess.includes('conclusion') || !hasGlobalAccess)">
                        <td>Conclusion</td>
                        <td v-html="item.discussion_conclusion"></td>
                    </tr>
                    <tr v-if="item.discussion_comment && (globalAccess.includes('comment') || !hasGlobalAccess)">
                        <td>Comment</td>
                        <td v-html="item.discussion_comment"></td>
                    </tr>
                </tbody>
            </table>  

        </template>

            

             
 


        <div class="button-fixed">
            <button type="button" class="btn">Discussion</button>
        </div>
    </div>
   
  
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
    props: {
        data: {
            type: Object,
            required: true
        },
    },
    setup() {
        const store = useStore() 
        const globalAccess = computed(() => store.state.view_selection.discussion)        
        const hasGlobalAccess = computed(() => store.state.view_selection.discussion.length)         
        return {globalAccess, hasGlobalAccess}
    }
}
</script>