<template>
    <teleport to="body">
    <div class="modal fade" id="modalAlertBox" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-blue">
                <div class="confirm-feedback">                    
                    <p v-html="textAlert"></p>                   
                    <div class="text-center">
                        <button class="btn btn-sea-blue mb-1"  style="width: 130px;" data-bs-dismiss="modal"> OK</button>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>
<script>
/**
 * how to use this components
 * call with store Ex. store.commit('users/setTextAlert', 'The field cannot be empty.')
 */
import { computed, onMounted,  watch } from 'vue'
import { useStore } from 'vuex'
import { Modal } from 'bootstrap'
export default {
    setup(){
        const store = useStore()
        const textAlert = computed(() => store.state.users.text_alert);       
        let modal = null;
        
        onMounted(() => {
            const myModalEl = document.getElementById('modalAlertBox');
            myModalEl.addEventListener('hidden.bs.modal', afterHideModal);
            modal = new Modal(document.getElementById('modalAlertBox'));    
        });

        watch(textAlert, ()  => {
            if(textAlert.value){
                modal.show()
            }               
        })

        const afterHideModal = () => {
            store.commit('users/setTextAlert', "") 
        }
        
        return { textAlert }
    }
}
</script>