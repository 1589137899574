<template>
<teleport to="body">
    <div class="modal fade modal-basecase" :id="idModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-blue">
                <ModalBaseCaseTable  v-if="showTable" :data="data" :idTable="'table-'+idModal"/>
            </div>
        </div>
    </div>
</teleport>
</template>
<script>
import { computed, ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import ModalBaseCaseTable from '@/components/result/ModalBaseCaseTable.vue'
import { mergeRow_class } from '@/utils'
export default {
    components:{
        ModalBaseCaseTable
    },
    props: {
        idModal:String
    },
    setup(props){
        const store = useStore()
        /**
         *  get data from vuex
         */
        const data = computed(() => store.state.result.base_case_modal )
        const showTable = ref(false)

        /**
         *  When show pop-up, merge cells.
         */
        const onShowModal = () => {
            mergeRow_class("#table-"+props.idModal+ ' .table-sub', [0,1])        
        }
        /**
         *  set hide pop-up
         */
        const setHideTable = () => {
            showTable.value = false             
        }
        watch(data, ()  => {
            if(data.value && data.value.length > 0){                 
                showTable.value = true
                setTimeout(() => onShowModal() , 100);
                
            }else{
                showTable.value = false
            } 
        })         
        
        onMounted(() => {
            const myModalEl = document.getElementById(props.idModal)
            myModalEl.addEventListener('hidden.bs.modal', setHideTable)
            // myModalEl.addEventListener('shown.bs.modal', onShowModal)
        })
        
         
        return{ data, showTable }
    }
}
</script>
 