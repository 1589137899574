<template>
    <div class="bg-white box-table-sub">
        <template v-for="(item, key)  in data.economic_analysis" :key="key">   
            <table class="table table-bordered" >
                <caption>Limitations</caption>
                <tbody>
                    <tr v-if="item.limitations_population && (globalAccess.includes('population') || !hasGlobalAccess)">
                        <td>Population</td>
                        <td v-html="item.limitations_population"></td>
                    </tr>
                    <tr v-if="item.limitations_measurement_of_long_term_outcome && (globalAccess.includes('extrapolation') || !hasGlobalAccess)">
                        <td>Extrapolation</td>
                        <td v-html="item.limitations_measurement_of_long_term_outcome"></td>
                    </tr>
                    <tr v-if="item.limitations_measurement_of_utility && (globalAccess.includes('measurement_utility') || !hasGlobalAccess)">
                        <td>Measurement of utility</td>
                        <td v-html="item.limitations_measurement_of_utility"></td>
                    </tr>
                    <tr v-if="item.limitations_measurement_of_cost && (globalAccess.includes('measurement_cost') || !hasGlobalAccess)">
                        <td>Measurement of cost</td>
                        <td v-html="item.limitations_measurement_of_cost"></td>
                    </tr>
                    <tr v-if="item.limitations_uncertainty_of_clinical_parameters && (globalAccess.includes('clinical_evidence') || !hasGlobalAccess)">
                        <td>Clinical evidence</td>
                        <td v-html="item.limitations_uncertainty_of_clinical_parameters"></td>
                    </tr>
                    <tr v-if="item.limitations_uncertainty_of_cost_effectiveness_outcomes && (globalAccess.includes('uncertainty_cost_effectiveness_outcomes') || !hasGlobalAccess)">
                        <td>Uncertainty of cost-effectiveness outcomes</td>
                        <td v-html="item.limitations_uncertainty_of_cost_effectiveness_outcomes"></td>
                    </tr>
                    <tr v-if="item.limitations_model_design && (globalAccess.includes('model_design') || !hasGlobalAccess)">
                        <td>Model design</td>
                        <td v-html="item.limitations_model_design"></td>
                    </tr>
                    <tr v-if="item.limitations_comparators && (globalAccess.includes('comparators') || !hasGlobalAccess)">
                        <td>Comparators</td>
                        <td v-html="item.limitations_comparators"></td>
                    </tr>
                    <tr v-if="item.limitations_assumptions && (globalAccess.includes('assumptions') || !hasGlobalAccess)">
                        <td>Assumptions</td>
                        <td v-html="item.limitations_assumptions"></td>
                    </tr>
                    <tr v-if="item.limitations_price && (globalAccess.includes('price') || !hasGlobalAccess)">
                        <td>Price</td>
                        <td v-html="item.limitations_price"></td>
                    </tr>
                    
                </tbody>
            </table>  

        </template>

            

             
 


        <div class="button-fixed">
            <button type="button" class="btn">Limitations</button>
        </div>
    </div>
   
  
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
    props: {
        data: {
            type: Object,
            required: true
        },
    },
    setup() {
        const store = useStore() 
        const globalAccess = computed(() => store.state.view_selection.limitation)        
        const hasGlobalAccess = computed(() => store.state.view_selection.limitation.length)
        
        return {globalAccess, hasGlobalAccess}
    }
}
</script>