<template>
    <div class="bg-white box-table-data-sub">
        <div class="box-btn-header">
            <span v-if="dataChart.Manufacturer && dataChart.Manufacturer['series'][0]['data'].length" :class="classBtnManufacturer" @click="changeType('Manufacturer')">Manufacturer</span>
            <span v-if="dataChart.Agency && dataChart.Agency['series'][0]['data'].length" :class="classBtnAgency" @click="changeType('Agency')">Agency</span>

            <template v-if=" classBtnManufacturer === 'btn-gray' && classBtnAgency === 'btn-gray' ">
                <span :class="classBtnManufacturer" >Manufacturer</span>
                <span :class="classBtnAgency" >Agency</span>
            </template>
        </div>
        <div class="chart-in-table" style="overflow: auto;height: 450px;">
            <div v-if="showChart" > 
                <highcharts v-if="chartOptions.xAxis.categories.length" :options="chartOptions" :style="(chartOptions.xAxis.categories.length > 7 ? 'height:'+(chartOptions.xAxis.categories.length*60)+'px' : '') "></highcharts>
                <div v-else class="graph-nodata" > NO DATA</div>
            </div>
        </div>
        <div class="button-fixed-absolute">
            <button type="button" class="btn">Base case</button>
        </div>
    </div>

</template>

<script>
import { Chart } from "highcharts-vue";
import { reactive, onMounted, ref, computed, watch } from 'vue'
import { icer_icur } from '@/utils'
import image from "@/assets/images/icon/inputs-search-close.svg"
export default {
    components : {
        highcharts: Chart 
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },    
    setup(props) {

        const chartOptions = reactive({
            chart: {
                type: 'bar'
            },
            title: {
                text: null
            },
            xAxis: {   
                // temp['name'] = `<div class="category-label font-oxygen"><div class="font-oxygen-bold">${item.line_subgroup.trim()}</div><br />${item.comparator}${forDifferent}</div>`
                    
                categories:[],
                labels: {                    
                    useHTML: true, 
                    formatter: function () { 
                        if(Object.keys(dataChart.value).length && dataChart.value[activeChart.value]['categories'][this.value] ){
                            return `<div class="category-label font-oxygen text-right"><div class="font-oxygen-bold">${dataChart.value[activeChart.value]['categories'][this.value]['text1']}</div>${dataChart.value[activeChart.value]['categories'][this.value]['text2']}</div>`;
                        }
                        return null
                    },                    
                },
            },
            yAxis: {
                min:0,
                title: {
                    text: null
                }
            },
            exporting: { enabled: false },
            legend: { enabled: false },
            credits: { enabled: false  },            
            tooltip: {
                formatter: function () {   
                    if(this.point.comment && this.point.comment.length){      
                        return '<div style="background: #fff">'+this.point.comment+"</div>";
                    }
                    return false;
                },
                useHTML: true,
                backgroundColor: '#fff'
            },
            series: []
        });

        const dataChart = ref({})
        const activeChart = ref("")
        //const categories = ref({})
        /**
         *  fetch data and set series to chartOptions
         */
        const fetchData = () => {
            if(props.data.type_of_economic_analysis !== "cea" && props.data.type_of_economic_analysis !== "cua"){  
                return false;
            }
            const result = {
                'Manufacturer' : {categories : {}, series : [
                    { name : "", type : "scatter", marker : { symbol: 'url('+image+')' }, color : '#FFC000', data : []}, 
                    { name : "", type : "columnrange", data : []},
                    { name : "", type : "bar", data : []} ]},
                'Agency' : {categories : {}, series : [
                    { name : "", type : "scatter", marker : { symbol: 'url('+image+')' }, color : '#FFC000', data : []}, 
                    { name : "", type : "columnrange", data : []},
                    { name : "", type : "bar", data : []} ]},
                'none' : {categories : {}, series : [
                    { name : "", type : "scatter", marker : { symbol: 'url('+image+')' }, color : '#FFC000', data : []}, 
                    { name : "", type : "columnrange", data : []},
                    { name : "", type : "bar", data : []} ]},
            }  
            
            if(props.data.economic_analysis){
                props.data.economic_analysis.forEach((item) => {
                    if(item.economic_analysis_result.length){
                        item.economic_analysis_result.forEach((item2) => {
                            if(item2.scenario && item2.scenario.length){
                                item2.scenario.forEach((item3, k) => {          
                        
                                                                           
                                    if(item3.parameter || item3.comment ){

                                        let icer = props.data.type_of_economic_analysis === "cea" ? item3.icer : item3.icur;
                                        let texticer = props.data.type_of_economic_analysis === "cea" ? "ICER" : "ICUR";
                                        let textChart = icer_icur(icer, item3.exceed, item3.less, item3.na, item3.dominated, item3.reference)
                                                            
                                                            
                                        let temp1 = null //scatter
                                        let temp2 = [null, null] //columnrange
                                        let temp3 = null //bar


                                        if(item3.exceed && icer > 0){
                                            temp1 = {y:icer, comment: `${texticer}: > ${icer.toString()}` };                                                               
                                        }else if(icer > 0){
                                            temp1 = {y:icer, comment:`${texticer}: ${icer.toString()}`}; 
                                        }else if(item3.na || item3.reference || item3.dominated){
                                            temp3 = {
                                                y: 0, color: "rgba(76, 158, 104, 0)",
                                                dataLabels:{ enabled: true, useHTML: true, verticalAlign:'middle' , formatter: function () { 
                                                    return `<div style="padding-left: 4px;">${textChart}</div>`
                                                }}
                                            }
                                        }else if(item3.range_min > 0 && item3.range_max > 0){
                                            temp2 = {
                                                low: item3.range_min,
                                                high: item3.range_max,
                                                comment: `${texticer}: MIN ${item3.range_min.toString()}, MAX ${item3.range_max.toString()}`
                                            }                        
                                        }


                                        if(item2.source_of_analysis){    
                                            result[item2.source_of_analysis]['categories'][k] = {text1 : item3.parameter, text2 : item3.comment};
                                            result[item2.source_of_analysis]['series'][0]['data'].push(temp1);
                                            result[item2.source_of_analysis]['series'][1]['data'].push(temp2);
                                            result[item2.source_of_analysis]['series'][2]['data'].push(temp3);
                                        }else{ 
                                            result['none']['categories'][k] = {text1 : item3.parameter, text2 : item3.comment};                        
                                            result['none']['series'][0]['data'].push(temp1);
                                            result['none']['series'][1]['data'].push(temp2);
                                            result['none']['series'][2]['data'].push(temp3);
                                        }
                                    }
                                })     
                            }
                        })                        
                    } 
                })
            }    
                   
                    
                     
                               
              
           
            dataChart.value = result
            Object.entries(result).forEach(([key,value])=>{
                if(value['series'][0]['data'].length && !activeChart.value){                    
                    activeChart.value = key;
                    chartOptions.series = value['series']
                    chartOptions.xAxis.categories = Object.keys(value['categories'])
                }
                
            });
        }

        watch(activeChart, ()  => {
            if(activeChart.value !== "" && Object.keys(dataChart.value).length){
                check_value()  
            } 
               
        }) 
        const check_value = () => {
            let found = false;            
            dataChart.value[activeChart.value]['series'].forEach(data => {
                data.data.forEach(val => {
                    console.log(val)
                    if(val && ((typeof val === 'object' && val.high && val.high > 0 ) || 
                        (typeof val === 'object' && val.y && val.y > 0) ||
                        (val && val > 0)) ){                 
                        found = true;
                    }
                })
            })
            if(found){
                chartOptions.yAxis = {
                    min:0, 
                    title: {
                        text: null
                    }
                }
            }else{
                chartOptions.yAxis = {
                    min:0,max:100,
                    title: {
                        text: null
                    }
                }
            }
        }
        /**
         *  bind class Manufacturer 
         */
        const classBtnManufacturer = computed(() => {  
            if(activeChart.value == "Manufacturer"){
                return "btn-orange";
            }else if(dataChart.value["Manufacturer"] && dataChart.value["Manufacturer"]['series'][0]['data'].length){
                return "btn-White cursor-pointer";
            }
            return "btn-gray"; 
        })
        /**
         *  bind class Agency  
         */
        const classBtnAgency = computed(() => {  
            if(activeChart.value == "Agency"){
                return "btn-orange";
            }else if(dataChart.value["Agency"] && dataChart.value["Agency"]['series'][0]['data'].length){
                return "btn-White cursor-pointer";
            }
            return "btn-gray"; 
        })

        /**
         * showChart for switch type and redraw
         */
        const showChart = ref(true);
        /**
         *  change Type between (Manufacturer, Agency)
         * @property {String} type
         */
        const changeType = (type) => {
            if(dataChart.value[type]['series'][0]['data'].length){
                showChart.value = false
                setTimeout(() => {
                    showChart.value = true;
                    chartOptions.series = dataChart.value[type]['series']
                    chartOptions.xAxis.categories = Object.keys(dataChart.value[type]['categories'])
                } , 50); 
                activeChart.value = type;
            }
        }        

        onMounted(() => {
            fetchData();           
        })

         
        return {chartOptions, dataChart, activeChart, classBtnManufacturer, classBtnAgency, changeType, showChart}
    }
}
</script>