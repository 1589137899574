<template>  
    <div class="container">
        <Header page="result" />
        <TopSearch  />
        <ModalExport @request_excel="request_excel"/>
        <div v-if="countRequest == 1 && datas.data && datas.data.length == 0" class="result-nodata"> No results found...</div>
        <div v-if="!(countRequest == 1 && datas.data && datas.data.length == 0)">    
            <ChartLimitationLandscape v-if="chartActive == 'limitation_landscape'"  @request_data="request_data" @request_result_chart_limitation_landscape="request_result_chart_limitation_landscape" />
            <ChartBaseCase v-if="chartActive == 'base_case'" @request_data="request_data" @request_result_chart_base_case="request_result_chart_base_case" @request_result_chart_base_case_country="request_result_chart_base_case_country"/>
            <ChartAgency v-if="chartActive == 'agency'" @request_data="request_data" />
            <ChartTherapeuticArea v-if="chartActive == 'therapeutic_area'" @request_data="request_data" />
            <ChartDrug v-if="chartActive == 'drug'" @request_data="request_data" />
            <ChartTypeEconomic v-if="chartActive == 'type_economic'" @request_data="request_data" />
            <ChartHealthOutcome v-if="chartActive == 'health_outcome'" @request_data="request_data" />

            <div class="box-bg-lightgray box-radius-2 p-3 mb-3">
                <div class="row row-ct" style="margin-right: 2px;">
                    <div class="col-12 col-md-3 col-lg-2 pe-0 mb-1 mt-1">
                        <div class="box-radius-black cursor-pointer" :class="chartActive == 'limitation_landscape' ? 'btn-bg-blue' : ''" @click="chartActive = 'limitation_landscape'">Limitation landscape</div>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 pe-0 mb-1 mt-1">
                        <div class="box-radius-black cursor-pointer" :class="chartActive == 'base_case' ? 'btn-bg-blue' : ''" @click="chartActive = 'base_case'">ICER landscape (base case)</div>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 pe-0 mb-1 mt-1">
                        <div class="box-radius-black cursor-pointer" :class="chartActive == 'agency' ? 'btn-bg-blue' : ''" @click="chartActive = 'agency'">Economic evaluation by agency</div>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 pe-0 mb-1 mt-1">
                        <div class="box-radius-black cursor-pointer" :class="chartActive == 'therapeutic_area' ? 'btn-bg-blue' : ''" @click="chartActive = 'therapeutic_area'">Economic evaluation by therapeutic area</div>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 pe-0 mb-1 mt-1">
                        <div class="box-radius-black cursor-pointer" :class="chartActive == 'drug' ? 'btn-bg-blue' : ''" @click="chartActive = 'drug'">Economic evaluation by drug</div>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 pe-0 mb-1 mt-1">
                        <div class="box-radius-black cursor-pointer" :class="chartActive == 'type_economic' ? 'btn-bg-blue' : ''" @click="chartActive = 'type_economic'">Type of economic analysis</div>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 pe-0 mb-1 mt-1">
                        <div class="box-radius-black cursor-pointer" :class="chartActive == 'health_outcome' ? 'btn-bg-blue' : ''" @click="chartActive = 'health_outcome'">Economic health outcome</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!(countRequest == 1 && datas.data && datas.data.length == 0)" class="main-overlay-np box-bg-gray mb-3">
            <div class="text-center">
                <div class="tb-checkbox"><input class="form-check-input" type="checkbox" id="checkAllBox" v-model="checkAllBox"> <label for="checkAllBox">Select all</label></div>
                <div>
                    <div class="box-tab-arrow"><span class="fs-4 me-3" data-bs-toggle="collapse" href="#collapseTabs" role="button" aria-expanded="true">View Selection</span>
                        <span data-bs-toggle="collapse" href="#collapseTabs" role="button" aria-expanded="true">
                            <span class="span-chevron-down" ><font-awesome-icon icon="chevron-down" :style="{fontSize : '15px', padding : 0}" /></span>
                            <span class="span-chevron-up" ><font-awesome-icon icon="chevron-up" :style="{fontSize : '15px', padding : 0}" /></span>
                        </span>
                    </div>
                    <div class="row row-ct mt-3 pb-4 wrapper-box collapse show" id="collapseTabs">
                        <div class="col-12 col-md-4 pe-5">
                            <div class="box-bg-lightgray box-details mb-2">
                                <p><input class="form-check-input" type="checkbox" id="checkAllMethod" v-model="checkAllMethod"> <label for="checkAllMethod">Method</label></p>
                                <div class="box-form">
                                    <ul>
                                        <li v-for="(val, key) in boxMethod" :key="key">
                                            <input class="form-check-input" type="checkbox" v-model="checkMethod" :value="val.value" :id="`boxMethod${key}`"> <label :for="`boxMethod${key}`">{{val.label}}</label>
                                        </li>                                       
                                    </ul>
                                </div>
                            </div>
                            <div class="box-bg-lightgray box-details">
                                <p><input class="form-check-input" type="checkbox" id="checkAllTableData" v-model="checkAllTableData"> <label for="checkAllTableData">Table Data</label></p>
                                <div class="box-form">
                                    <ul>
                                        <li v-for="(val, key) in boxTableData" :key="key">
                                            <input class="form-check-input" type="checkbox" v-model="checkTableData" :value="val.value" :id="`boxTableData${key}`"> <label :for="`boxTableData${key}`">{{val.label}}</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-4 pe-5">
                            <div class="box-bg-lightgray box-details mb-2">
                                <p><input class="form-check-input" type="checkbox" id="checkAllDiscussion" v-model="checkAllDiscussion"> <label for="checkAllDiscussion">Discussion</label></p>
                                <div class="box-form">
                                    <ul>
                                        <li v-for="(val, key) in boxDiscussion" :key="key">
                                            <input class="form-check-input" type="checkbox" v-model="checkDiscussion" :value="val.value" :id="`boxDiscussion${key}`"> <label :for="`boxDiscussion${key}`">{{val.label}}</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="box-bg-lightgray box-details">
                                <p><input class="form-check-input" type="checkbox" id="checkAllGraphData" v-model="checkAllGraphData"> <label for="checkAllGraphData">Graph Data</label></p>
                                <div class="box-form">
                                    <ul>
                                        <li v-for="(val, key) in boxGraphData" :key="key">
                                            <input class="form-check-input" type="checkbox" v-model="checkGraphData" :value="val.value" :id="`boxGraphData${key}`"> <label :for="`boxGraphData${key}`">{{val.label}}</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-4">
                            <div class="box-bg-lightgray box-details">
                                <p><input class="form-check-input" type="checkbox" id="checkAllLimitation" v-model="checkAllLimitation"> <label for="checkAllLimitation">Limitations</label></p>
                                <div class="box-form">
                                    <ul>
                                        <li v-for="(val, key) in boxLimitation" :key="key">
                                            <input class="form-check-input" type="checkbox" v-model="checkLimitation" :value="val.value" :id="`boxLimitation${key}`"> <label :for="`boxLimitation${key}`">{{val.label}}</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div  v-if="load" class="overlay">
                <div class="loadding_circle"></div>
            </div>
        </div>

        <div v-if="!(countRequest == 1 && datas.data && datas.data.length == 0)" class="main-overlay-np box-bg-gray mb-5">
            <div class="table-responsive">
                <table class="table table-result table-borderless">
                    <caption>table result</caption>
                    <thead>
                    <tr class="tr-black">
                        <th scope="col" width="10"></th>
                        <th scope="col" width="8%">Country</th>
                        <th scope="col" width="15%">Brand name/active ingredient</th>
                        <th scope="col" width="15%">Therapeutic area</th>
                        <th scope="col" >Indication</th>
                        <th scope="col" width="10%">Treatment line</th>
                        <th scope="col" width="12%">Type of economic analysis</th>
                        <th scope="col" width="15%">Recommendation</th>
                        <th scope="col" width="10" style="padding:4px"><font-awesome-icon icon="chevron-down" :style="{ fontSize: '12px' }"/></th>
                    </tr>
                    </thead>
                <tbody>
                    <template v-if="datas && datas.data && datas.data.length">   
                        <template v-for="(item, key) in datas.data" :key="key">                 
                        <tr :class="!hideDetailSingle.includes(item.pk_id) && (showDetailSingle.includes(item.pk_id) || hasViewSelection) ? 'tr-darkgray' : 'tr-gray'">                        
                            <td>
                                <router-link :to="'/detail/'+item.drug_id+'/'+item.agency_model.toLowerCase()" target="_blank" rel="noopener">
                                    <font-awesome-icon :icon="['far', 'lightbulb']"  :style="{ fontSize: '13px' }" />
                                </router-link>                                
                            </td>
                            <td>
                                <div class="acronym-flag"><em class="flag size20" :class="item.icon_flags"></em> <span>{{ item.acronym }}</span></div>        
                                <div>{{ DDMMMYY(item.guide_date) }}</div>
                            </td>
                            <td>{{ `${item.drug_name} / ${item.dci_new}` }}</td>
                            <td><div v-html="array_to_html(item.indexation)"></div></td>
                            <td><div v-html="item.title"></div></td>
                            <td>
                                <div>{{ item.treatment_line ? `Line : ${treatment_line(item.treatment_line)}` : ""}}</div>
                                <div>{{ item.treatment_specificity ?  `Specificity : ${item.treatment_specificity}` : ""}}</div>
                                <div>{{ item.treatment_administration ?  `Administration : ${item.treatment_administration}` : ""}}</div> 
                            </td>
                            <td>{{ item.type_of_economic_analysis ? $t(item.type_of_economic_analysis) : ""}}</td>
                            <td class="relative">
                                <div v-html="display_recommendation(item)"></div>
                                <div class="i-plus cursor-pointer" @click="toggleRationaleComment(item.pk_id)" :class="!showRationaleComment.includes(item.pk_id) ? '' : 'bg-red'">
                                    <font-awesome-icon :icon="!showRationaleComment.includes(item.pk_id) ? 'plus' : 'minus' " :style="{ fontSize: '11px'}"/>
                                </div>
                            </td>
                            <td class="cursor-pointer" :view_id="item.id+'-'+item.drug_id" style="padding:4px" @click="toggleDetailSingle(item.pk_id)"><font-awesome-icon :icon="!hideDetailSingle.includes(item.pk_id) && (showDetailSingle.includes(item.pk_id) || hasViewSelection)  ? 'chevron-up' : 'chevron-down' " :style="{ fontSize: '12px' }"/></td>
                        </tr>
                        <tr class="tr-darkgray" v-if="showRationaleComment.includes(item.pk_id)">
                            <td colspan="9"> 
                                <RationaleComment :data="item" />                                
                            </td>
                        </tr>
                        
                        <SubTableBaseCase v-if="!hideDetailSingle.includes(item.pk_id) && (showDetailSingle.includes(item.pk_id) || hasViewSelection)" :data="item" />
                        <SubTableDetail v-if="!hideDetailSingle.includes(item.pk_id) && (showDetailSingle.includes(item.pk_id) || hasViewSelection)" :data="item" />
                                                
                        </template>
                    </template>
                    <template v-else-if="!load">
                        <tr class="tr-gray"><td colspan="9">NO DATA</td></tr>
                    </template>
                   
                    
                </tbody>

                </table>
            </div>
            <div class="pull-right">
                <pagination :data="datas" @pagination-change-page="onPagination" :limit="3"></pagination>
            </div>
            <div class="clearfix"></div>
            <div  v-if="load" class="overlay">
                <div class="loadding_circle"></div>
            </div>
        </div>

         
    </div>
    <Footer />
</template>

<script>


import { computed, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router';
import Header from '@/components/Layout/Header.vue' 
import Footer from '@/components/Layout/Footer.vue' 
import TopSearch from '@/components/result/TopSearch.vue'
import ModalExport from '@/components/result/ModalExport.vue'
import RationaleComment from '@/components/result/RationaleComment.vue'
import SubTableDetail from '@/components/result/SubTableDetail.vue'
import SubTableBaseCase from '@/components/result/SubTableBaseCase.vue'
import ChartBaseCase from '@/components/result/ChartBaseCase.vue'
import ChartLimitationLandscape from '@/components/result/ChartLimitationLandscape.vue'
import ChartAgency from '@/components/result/ChartAgency.vue'
import ChartTherapeuticArea from '@/components/result/ChartTherapeuticArea.vue'
import ChartDrug from '@/components/result/ChartDrug.vue'
import ChartTypeEconomic from '@/components/result/ChartTypeEconomic.vue'
import ChartHealthOutcome from '@/components/result/ChartHealthOutcome.vue'
import { DDMMMYY, array_to_html, color_recommendation, currentDate } from '@/utils'
import "@/assets/css/search.css";
import { useI18n } from 'vue-i18n'
import pagination from '@/components/elements/pagination.vue';
export default {
    components : {
        Header,
        TopSearch,
        ModalExport,
        RationaleComment,
        SubTableDetail,
        SubTableBaseCase,
        ChartLimitationLandscape,
        ChartBaseCase,
        ChartAgency,
        ChartTherapeuticArea,
        ChartDrug,
        ChartTypeEconomic,
        ChartHealthOutcome,
        pagination,
        Footer
    },
    setup() {
        const store = useStore()
        const route = useRoute()
        const { t } = useI18n()
        

        //----------------------------- get result table -------------------------- [start]
        const countRequest = ref(0)
        let last_paramObj = route.params;
        /**
         * request service api/result/... for get result search
         * @property {Object} param - page, filter, etc.
        */
        const request_data = (param = {}) => {
            countRequest.value = countRequest.value+1; 
            last_paramObj = {...route.params, ...param}    
            store.dispatch('result/result', last_paramObj)
        }
        request_data();   
        /**
         * Get data result search
        */    
        const datas = computed(() => store.state.result.data);        
        const load = computed(() => store.state.result.load );
        /**
         * On click pagination
         * @property {Integer} page 
        */
        const onPagination = (page = 1) => {
            request_data({page})
        }
        //----------------------------- get result table -------------------------- [end]


        const request_excel = (configColumn) => { 
            const paramObj = {...last_paramObj, ...configColumn}
            store.dispatch(`result/result_excel`, [paramObj, currentDate()+"_export" ] )
        }



        //----------------------------- get result chart -------------------------- [start]
        /**
         * set show Limitation landscape graph as default     
        */
        const chartActive = ref('limitation_landscape')
        /**
         * request service api/result_chart/... for get result chart [by agency, by therapeutic area, by drug, Type of economic analysis, Economic health outcome]          
        */
        store.dispatch('result/result_chart', {base64Search : route.params.base64Search });
        
        /**
         * request service api/result_chart_base_case/... for get chart base case
         * @property {Object} param - filter, etc.
        */        
        const request_result_chart_base_case = (param = {}) => {
            let paramObj = {...route.params, ...param}
            paramObj['base64Search'] = paramObj['base64Search'].replaceAll('-', '+').replaceAll('_', '/')            
            store.dispatch('result/result_chart_base_case', paramObj)
        }
        request_result_chart_base_case()  //request chart
        request_result_chart_base_case({'table' : true})  //request table in modal

        /**
         * request service api/result_chart_base_case_country/... for get sub chart base case
         * @property {Object} param - filter, etc.
        */
        const request_result_chart_base_case_country = (param = {}) => {
            let paramObj = {...route.params, ...param}
            paramObj['base64Search'] = paramObj['base64Search'].replaceAll('-', '+').replaceAll('_', '/')            
            store.dispatch('result/result_chart_base_case_country', paramObj)
        }
        /**
         * request service api/result_chart_limitation_landscape/... for get chart Limitation landscape
         * @property {Object} param - filter, etc.
        */
        const request_result_chart_limitation_landscape = (param = {}) => {
            let paramObj = {...route.params, ...param}
            paramObj['base64Search'] = paramObj['base64Search'].replaceAll('-', '+').replaceAll('_', '/')            
            store.dispatch('result/result_chart_limitation_landscape', paramObj)
        }
        request_result_chart_limitation_landscape(); 
        //----------------------------- get result chart -------------------------- [end]

        


        //------------------------ View Selection ---------------------------------------------[start]         
        /**
         * List Method
         */
        const boxMethod = [
            { label : "Analytic Framework", value : 'analytic_framework'},
            { label : "Structure of the model", value : 'structure_model'},
            { label : "Measurement of effectiveness", value : 'measurement'},
            { label : "Extrapolation", value : 'extrapolation'},
            { label : "Utility values", value : 'utility_values'},
            { label : "Resources and costs", value : 'resources_costs'},
            { label : "Sensitivity analysis", value : 'sensitivity_analysis'}
        ];
        /**
         * event check/uncheck list Method [v-model]
         */
        const checkMethod = computed({
            get () { return store.state.view_selection.method },
            set (val) {store.commit('view_selection/setMethod', val) }
        }); 
        /**
         * event check all/uncheck all list Method [v-model]
         */
        const checkAllMethod = computed({
            set(val){
                let temp = []
                if(val){ boxMethod.forEach(v=>{ temp.push(v.value)}); }
                store.commit('view_selection/setMethod', temp) 
            },
            get(){ return store.state.view_selection.method.length === boxMethod.length; }
        });

        /**
         * List Discussion
         */ 
        const boxDiscussion = [
            { label : "Study finding", value : 'study_finding'},
            { label : "Assumptions", value : 'assumptions'},
            { label : "Comparison with other studies", value : 'comparison_studies'},
            { label : "Validation", value : 'validation'},
            { label : "Transferability", value : 'transferability'},
            { label : "Extrapolation", value : 'extrapolation'},
            { label : "Conclusion", value : 'conclusion'},
            { label : "Comment", value : 'comment'}
        ];
        /**
         * event check/uncheck list Discussion [v-model]
         */
        const checkDiscussion = computed({
            get () { return store.state.view_selection.discussion },
            set (val) {store.commit('view_selection/setDiscussion', val) }
        }); 
        /**
         * event check all/uncheck all list Discussion [v-model]
         */
        const checkAllDiscussion = computed({
            set(val){
                let temp = []
                if(val){ boxDiscussion.forEach(v=>{ temp.push(v.value)}); }
                store.commit('view_selection/setDiscussion', temp) 
            },
            get(){ return store.state.view_selection.discussion.length === boxDiscussion.length; }
        });

        /**
         * List Limitations
         */ 
        const boxLimitation = [
            { label : "Population", value : 'population'},
            { label : "Extrapolation", value : 'extrapolation'},
            { label : "Measurement of utility", value : 'measurement_utility'},
            { label : "Measurement of cost", value : 'measurement_cost'},
            { label : "Clinical evidence", value : 'clinical_evidence'},
            { label : "Uncertainty of cost-effectiveness outcomes", value : 'uncertainty_cost_effectiveness_outcomes'},
            { label : "Model design", value : 'model_design'},
            { label : "Comparators", value : 'comparators'},
            { label : "Assumptions", value : 'assumptions'},
            { label : "Price", value : 'price'}
        ]; 
        /**
         * event check/uncheck list Limitations [v-model]
         */
        const checkLimitation = computed({
            get () { return store.state.view_selection.limitation },
            set (val) {store.commit('view_selection/setLimitation', val) }
        }); 
        /**
         * event check all/uncheck all list Limitations [v-model]
         */
        const checkAllLimitation = computed({
            set(val){
                let temp = []
                if(val){ boxLimitation.forEach(v=>{ temp.push(v.value)}); }
                store.commit('view_selection/setLimitation', temp) 
            },
            get(){ return store.state.view_selection.limitation.length === boxLimitation.length; }
        });


        /**
         * List Table Data
         */ 
        const boxTableData = [
            { label : "Base case", value : 'base_case'},
            { label : "Scenario analysis", value : 'scenario'},
            { label : "Deterministic sensitivity analysis", value : 'deterministic'},
            { label : "Probabilistic sensitivity analysis", value : 'probabilistic'}           
        ];
        /**
         * event check/uncheck list Table Data [v-model]
         */
        const checkTableData = computed({
            get () { return store.state.view_selection.table_data },
            set (val) {store.commit('view_selection/setTableData', val) }
        });
        /**
         * event check all/uncheck all list Table Data [v-model]
         */
        const checkAllTableData = computed({
            set(val){
                let temp = []
                if(val){ boxTableData.forEach(v=>{ temp.push(v.value)}); }
                store.commit('view_selection/setTableData', temp) 
            },
            get(){ return store.state.view_selection.table_data.length === boxTableData.length; }
        });

        /**
         * List Graph Data
         */ 
        const boxGraphData = [
            {label : "Base case", value : 'base_case'},
            {label : "Scenario analysis", value : 'scenario'}
        ]
        /**
         * event check/uncheck list Graph Data [v-model]
         */
        const checkGraphData = computed({
            get () { return store.state.view_selection.graph_data },
            set (val) {store.commit('view_selection/setGraphData', val) }
        }); 
        /**
         * event check all/uncheck all list Graph Data [v-model]
         */
        const checkAllGraphData = computed({
            set(val){
                let temp = []
                if(val){
                    boxGraphData.forEach(v=>{      
                        temp.push(v.value)
                    });
                }
                store.commit('view_selection/setGraphData', temp)
            },
            get(){ return store.state.view_selection.graph_data.length === boxGraphData.length; }
        });

        /**
         * event check all/uncheck all of View Selection [v-model]
         */
        const checkAllBox = computed({
            set(val){
                checkAllGraphData.value = val
                checkAllMethod.value = val
                checkAllTableData.value = val
                checkAllLimitation.value = val
                checkAllDiscussion.value = val
            },
            get(){ return checkAllMethod.value && checkAllTableData.value && checkAllLimitation.value && checkAllDiscussion.value && checkAllGraphData.value }
        });

        
        
        

        
 
        /**
         *  toggle box Rationnel / Commentaires
         * @property {integer} econId - pk_id
         */
        let showRationaleComment = ref([])
        const toggleRationaleComment = (econId) => {
            if(showRationaleComment.value.indexOf(econId) !== -1){
                showRationaleComment.value.splice(showRationaleComment.value.indexOf(econId), 1)
            }else{
                showRationaleComment.value.push(econId)
            }
        }
        
        /**
         *  toggle detail box Method, Discussion, Limitations, Table Data, Graph Data
         * @property {integer} econId - pk_id
         */
        let showDetailSingle = ref([])
        let hideDetailSingle = ref([])
        const toggleDetailSingle = (econId) => {  
            if(showDetailSingle.value.indexOf(econId) !== -1){
                showDetailSingle.value.splice(showDetailSingle.value.indexOf(econId), 1)
                hideDetailSingle.value.push(econId)
            }else if(hideDetailSingle.value.indexOf(econId) !== -1){
                hideDetailSingle.value.splice(hideDetailSingle.value.indexOf(econId), 1); 
                showDetailSingle.value.push(econId)
            }else if(hasViewSelection.value){
                hideDetailSingle.value.push(econId)
            }else{
                showDetailSingle.value.push(econId)
            }
            
        }    
        
        /**
         * check if there is information view selection 
         */
        const hasViewSelection = computed(() => {
            return checkMethod.value.length +
            checkDiscussion.value.length +
            checkLimitation.value.length +
            checkTableData.value.length + 
            checkGraphData.value.length;
        })
        watch(hasViewSelection, ()  => {
            if(hideDetailSingle.value.length > 0){
                hideDetailSingle.value = []
            }
        })
        
        //------------------------ View Selection ---------------------------------------------[end]

            


        //----------------------- helper method for display result table -------------------------[start]
        /**
         *  helper of treatment line
         * @property {String} param
         * @return {String}
         */
        const treatment_line = (param) => {
            const txt = [];
            if(param){   
                const arr = param.split(",")     
                for(const val of arr){ 
                    if(val){
                        txt.push(t('treatment_line.'+val))
                    }                    
                }       
            }
            return txt.join(', ');
        }

        /**
         *  helper of recommendation
         * @property {Object} param
         * @return {String}
         */
        const display_recommendation = (param) => {
            const result = []
            if(param.recommendation){
                for(const val of param.recommendation){ 
                    if(val){ 
                        if(param.agency_model === "Ceesp"){
                            result.push(`<div>${t('ceesp.appreciation.'+val)}</div>`);                            
                        }else if(param.agency_model === "Nice"){
                            result.push(`<div>${t(val)}</div>`);                            
                        }else if(['Smc', 'Perc'].includes(param.agency_model) ){
                            result.push(`<div>${val}</div>`);                            
                        }else{
                            result.push(`<div>${val}</div>`);
                        }
                    }                    
                }
            }
            return result.join(' ');
        }

        //Now not use [ keep for when use with color ]
        const display_recommendation_color = (param) => {
            const result = []
            if(param.recommendation){
                for(const val of param.recommendation){ 
                    if(val){ 
                        if(param.agency_model === "Ceesp"){                            
                            result.push(`<div class="blue">${t('ceesp.appreciation.'+val)}</div>`); 
                        }else if(param.agency_model === "Nice"){                            
                            result.push(`<div class="${color_recommendation(val)}">${t(val)}</div>`); 
                        }else if(['Smc', 'Perc'].includes(param.agency_model) ){                            
                            result.push(`<div class="${color_recommendation(val)}">${val}</div>`); 
                        }else if(val === "Accepted"){ 
                            result.push(`<div class="blue">${val}</div>`);                           
                        }else{
                            result.push(`<div>${val}</div>`);
                        }
                    }                    
                }
            }
            return result.join(' ');
        }
        //----------------------- helper method for display result table ------------------------- [end]

        onUnmounted(() => {
            store.commit('result/clearState');
            store.commit('view_selection/clearState');
        })

        return {datas, load, DDMMMYY, array_to_html, treatment_line, display_recommendation,
        showRationaleComment, toggleRationaleComment, toggleDetailSingle, showDetailSingle, hideDetailSingle,
        boxMethod, checkMethod, boxDiscussion, checkDiscussion, boxLimitation, checkLimitation, 
        boxTableData, checkTableData, boxGraphData, checkGraphData, checkAllMethod,
        checkAllDiscussion, checkAllLimitation, checkAllTableData, checkAllGraphData, checkAllBox,
        chartActive, request_data, onPagination, display_recommendation_color,
        request_result_chart_base_case, request_result_chart_base_case_country,
        request_result_chart_limitation_landscape, countRequest, hasViewSelection, request_excel }
    },
    

}
</script>