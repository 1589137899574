<template>
<teleport to="body">
    <div class="modal fade modal-export" id="modal-export" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-blue main-overlay-np">
                <div class="text-center" style="padding: 12px 17px;">
                    <h4>Excel export</h4>                    
                </div> 
                <div class="action-popup"  >
                    <div class="inline" style="margin-right:8px">
                        <input class="form-check-input" type="checkbox" id="checkAll" v-model="checkAll" style="margin-right:5px"> 
                        <label for="checkAll"> Select all</label>
                    </div>                
                    <button class="btn btn-red inline font-oxygen" data-bs-dismiss="modal" aria-label="Close">CLOSE <font-awesome-icon icon="times" /></button>
                </div>
                <div v-if="list_limitation !== null" class="row row-ct mt-3 pb-4 wrapper-box collapse show" id="collapseTabs">
                    <div class="col-12 col-md-4 pe-5">
                        <div class="box-bg-lightgray box-details mb-2">
                            <p><input class="form-check-input" type="checkbox" id="exportAllGeneral" v-model="checkAllGeneral"> <label for="exportAllGeneral">{{list_general.text}}</label></p>
                            <div class="box-form">
                                <ul>
                                    <li v-for="(val, key) in list_general.columns" :key="key">
                                        <input class="form-check-input" type="checkbox" v-model="state_general" :value="val.code" :id="`exportGeneral${key}`"> <label :for="`exportGeneral${key}`">{{val.text}}</label>
                                    </li>                                       
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-4 pe-5">
                        <div class="box-bg-lightgray box-details mb-2">
                            <p><input class="form-check-input" type="checkbox" id="exportAllDiscussion" v-model="checkAllDiscussion"> <label for="exportAllDiscussion">{{list_discussion.text}}</label></p>
                            <div class="box-form">
                                <ul>
                                    <li v-for="(val, key) in list_discussion.columns" :key="key">
                                        <input class="form-check-input" type="checkbox" v-model="state_discussion" :value="val.code" :id="`exportDiscussion${key}`"> <label :for="`exportDiscussion${key}`">{{val.text}}</label>
                                    </li>
                                </ul>
                            </div>
                        </div>                        
                    </div>

                    <div class="col-12 col-md-4">
                        <div class="box-bg-lightgray box-details">
                            <p><input class="form-check-input" type="checkbox" id="exportAllLimitation" v-model="checkAllLimitation"> <label for="exportAllLimitation">{{list_limitation.text}}</label></p>
                            <div class="box-form">
                                <ul>
                                    <li v-for="(val, key) in list_limitation.columns" :key="key">
                                        <input class="form-check-input" type="checkbox" v-model="state_limitation" :value="val.code" :id="`exportLimitation${key}`"> <label :for="`exportLimitation${key}`">{{val.text}}</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>    
                <div class="text-center btn-export" >
                    <button class="btn btn-sea-blue" @click="handleExport" >EXPORT <font-awesome-icon icon="chevron-right"  :style="{ fontWeight: '300', marginLeft:'8px' }" /></button>                
                </div> 
                
            
                <div v-if="load" class="overlay">
                    <div class="loadding_circle"></div>
                </div>
            </div>
            
        </div>
    </div>
</teleport>
</template>
<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex'
export default {   
     
    emits: ['request_excel'], 
    setup(props, { emit }) {

        const store = useStore()     
        const load = computed(() => store.state.result.load_excel );
        const export_columns = computed(() => store.state.result.export_columns );
        watch(export_columns, ()  => {       
            if(export_columns.value.length && list_general.value === null ){                  
                list_general.value = export_columns.value[0]; 
                list_discussion.value = export_columns.value[1];
                list_limitation.value = export_columns.value[2];  
            }        
        })
        /**
         * List & state General information
         */ 
        const list_general = ref(null); 
        const state_general = ref([]);        
        const checkAllGeneral = computed({
            set(val){
                let temp = []
                if(val){ list_general.value.columns.forEach(v=>{ temp.push(v.code)}); }
                state_general.value = temp;
            },
            get(){ return list_general.value !== null && state_general.value.length === list_general.value.columns.length; }
        });


        /**
         * List & state Discussion
         */ 
        const list_discussion = ref(null); 
        const state_discussion = ref([]);
        const checkAllDiscussion = computed({
            set(val){
                let temp = []
                if(val){ list_discussion.value.columns.forEach(v=>{ temp.push(v.code)}); }
                state_discussion.value = temp;
            },
            get(){ return list_discussion.value !== null && state_discussion.value.length === list_discussion.value.columns.length; }
        });

        /**
         * List & state Limitations
         */ 
        const list_limitation = ref(null);        
        const state_limitation = ref([]);
        const checkAllLimitation = computed({
            set(val){
                let temp = []
                if(val){ list_limitation.value.columns.forEach(v=>{ temp.push(v.code)}); }
                state_limitation.value = temp;
            },
            get(){ return list_limitation.value !== null && state_limitation.value.length === list_limitation.value.columns.length; }
        });

        /**
         * event check all/uncheck all
         */
        const checkAll = computed({
            set(val){  
                checkAllGeneral.value = val
                checkAllDiscussion.value = val
                checkAllLimitation.value = val
            },
            get(){ return checkAllGeneral.value && checkAllDiscussion.value && checkAllLimitation.value }
        });
        /**
         * Check if there is any information.
         * @property {Array} param
         * @return {boolean}
        */ 
        const handleExport = () => {
            
            if(state_general.value.length + state_discussion.value.length + state_limitation.value.length){
                let objParam = {
                    general : state_general.value,
                    discussion : state_discussion.value,
                    limitation : state_limitation.value,
                }            
                emit('request_excel', objParam)
            }else{
                store.commit('users/setTextAlert', 'Please select the data you want to export.')
            }
                        
        }  
        

        return { handleExport, list_general, state_general, list_discussion, state_discussion, list_limitation, 
        state_limitation, load, checkAll, checkAllLimitation, checkAllDiscussion, checkAllGeneral }//checkAll, checkAllLimitation, checkAllDiscussion, checkAllGeneral,
    }
}
</script>
