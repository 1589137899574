<template>
    <div class="bg-white box-table-data-sub">
                                   
        <table class="table table-bordered">
            <caption>Scenario analysis</caption>
            <thead>
                <tr>
                    <td style="width:10%">Scenario analysis</td>
                    <td style="width:22%">Line, subgroup</td>
                    <td style="width:11%">Comparator</td>
                    <td style="width:11%">Parameter (change)</td>
                    <template v-if="data.type_of_economic_analysis == 'cea' ">
                        <td style="width:5%">ICER</td>
                    </template>
                    <template v-else-if="data.type_of_economic_analysis == 'cua' ">
                        <td style="width:5%">ICUR</td>
                    </template>                                    
                    <td style="width:7%">Confidence interval(Min)</td>
                    <td style="width:7%">Confidence interval(Max)</td>
                    <td style="width:22.5%">Comment</td>
                </tr>
            </thead>
            <tbody>
                <template v-for="(Mitem, Mkey) in data.economic_analysis" :key="Mkey">
                <template v-if="Mitem.economic_analysis_result">
                    <template v-for="(Mitem2, Mkey2) in Mitem.economic_analysis_result" :key="Mkey2">
                        <template v-if="Mitem2.scenario">
                        <tr v-for="(item, key) in Mitem2.scenario" :key="key">
                            <td>{{ checkEmpty(Mitem2.source_of_analysis) }}</td>
                            <td>{{ checkEmpty(item.line_subgroup) }}</td>
                            <td>{{ checkEmpty(item.comparator_main) }}</td>
                            <td>{{ checkEmpty(item.parameter) }}</td>
                             <template v-if="data.type_of_economic_analysis == 'cea' ">
                                <td>{{ icer_icur(item.icer, item.exceed, item.less, item.na, item.dominated, item.reference, "-") }}</td>
                            </template>
                            <template v-else-if="data.type_of_economic_analysis == 'cua' ">
                                <td>{{ icer_icur(item.icur, item.exceed, item.less, item.na, item.dominated, item.reference, "-") }}</td>
                            </template>                    
                            <td>{{ checkEmpty(item.range_min) }}</td>
                            <td>{{ checkEmpty(item.range_max) }}</td>
                            <td>{{ checkEmpty(item.comment) }}</td>
                        </tr>
                        </template>
                    </template>
                </template>
                </template>
                                                   
            </tbody>
        </table>
        
        <div class="button-fixed">
            <button type="button" class="btn">Scenario analysis</button>
        </div>
    </div>  
</template>

<script>
import { checkEmpty, icer_icur } from '@/utils'
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    setup(){

        return {checkEmpty, icer_icur}
    }
}
</script>
