<template>
    <div class="box-bg-gray box-radius-1 main-overlay-np">
        <div class="row row-ct px-1">
            <div class="col-12 ">
                <div class="bg-white main-graph">
                <h3>Economic health outcome</h3>

                <div class="box-graph">
                    <highcharts v-if="chartOptions.series[0].data.length" :options="chartOptions" ></highcharts>
                    <div v-else-if="!loadChart" class="graph-nodata"> NO DATA</div>
                </div>
                </div>
            </div>
        </div>
        <div v-if="loadChart" class="overlay">
            <div class="loadding_circle"></div>
        </div>
    </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import { computed, reactive, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
export default {
    components : {
        highcharts: Chart 
    },
    emits: ['request_data'],
    setup(props, { emit }) {
        const chartOptions = reactive({
            chart: {
                type: 'bar'
            },
            title: {
                text: null
            },
            xAxis: {
                type: 'category',
            },
            yAxis: {
                title: {
                    text: null
                }
            },
            exporting: { enabled: false },
            legend: { enabled: false },
            credits: { enabled: false  },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                    }
                }
            },

            tooltip: {
                headerFormat : null,
                // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
            },

            series: [
                {
                    name: "Economic Health outcome",
                    color: '#3c98ce',
                    //colorByPoint: true,
                    point: {
                        events: {
                            click: function() {
                                console.log(this.name+"--"+this.y);
                                emit('request_data', {filter_health_outcome : this.name})
                            }
                        }
                        },
                    data: []
                }
            ]
        });

        const store = useStore()
        /**
         *  get data from vuex
         */
        const data = computed(() => store.getters['result/getDataChartByType']("health_outcome"))
        const loadChart = computed(() => store.state.result.load_chart_normal);  
        /**
         *  set series to chartOptions
         */
        const setDataToChart = () => {
            if(data.value && data.value.length > 0){                
                chartOptions.series[0].data = data.value              
            }
        }
        watch(data, ()  => {
            setDataToChart()     
        })         
        onMounted(() => {
            setDataToChart()
        })

        return {chartOptions, loadChart}
    }

}
</script>