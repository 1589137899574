<template>
    <div class="bg-white box-table-data-sub">
                                   
        <table class="table table-bordered">
            <caption>Probabilistic sensitivity analysis</caption>
            <thead>
                <tr>
                    <td style="width:10%">Probabilistic sensitivity analysis</td>
                    <td style="width:22%">Line, subgroup</td>
                    <td style="width:11%">Comparator</td>
                    <td style="width:5%">WTP</td>
                    <td style="width:7%">Probability</td>
                    <td style="width:5%">ICER</td>                   
                    <td style="width:7%">Confidence interval(Min)</td>
                    <td style="width:7%">Confidence interval(Max)</td>
                    <td style="width:22.5%">Comment</td>
                </tr>
            </thead>
            <tbody>
                <template v-for="(Mitem, Mkey) in data.economic_analysis" :key="Mkey">
                <template v-if="Mitem.economic_analysis_result">
                    <template v-for="(Mitem2, Mkey2) in Mitem.economic_analysis_result" :key="Mkey2">
                        <template v-if="Mitem2.probabilistic">
                        <tr v-for="(item, key) in Mitem2.probabilistic" :key="key">
                            <td>{{ checkEmpty(Mitem2.source_of_analysis) }}</td>
                            <td>{{ checkEmpty(item.line_subgroup) }}</td>
                            <td>{{ checkEmpty(item.comparator) }}</td>
                            <td>{{ checkEmpty(item.wtp) }}</td>
                            <td>{{ checkEmpty(item.probability) }}</td>
                            <td>{{ icer_icur(item.icer, item.exceed, item.less, item.na, item.dominated, item.reference, "-") }}</td>                            
                            <td>{{ checkEmpty(item.range_min) }}</td>
                            <td>{{ checkEmpty(item.range_max) }}</td>
                            <td>{{ checkEmpty(item.comment) }}</td>
                        </tr>
                        </template>
                    </template>
                </template>
                </template>
                                                   
            </tbody>
        </table>
        
        <div class="button-fixed">
            <button type="button" class="btn">Probabilistic sensitivity analysis</button>
        </div>
    </div>  
</template>

<script>
import { checkEmpty, icer_icur } from '@/utils'
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    setup(){

        return {checkEmpty, icer_icur}
    }
}
</script>