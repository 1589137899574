<template>
    <teleport to="body">
    <div class="modal fade modal-basecase" id="modal-limitation-main-country" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="flex-direction : column;">
            <div class="modal-content border-blue">
                  
                <div class="box-radius-1 main-overlay" style="min-height: 51px;" >
                    <div>
                        <div class="box-ui-tab mb-1 px-1">
                            <div class="row row-ct">
                                
                                <div class="col-12 col-md-5 pe-0">
                                    <div class="d-flex" style="font-size: 1rem;">
                                        <div style="width: 160px;"> Limitation landscape</div>
                                        <div style="width: 85px;"> <span class="flag size20" :class="objectAgency.icon_flags" style="vertical-align: middle;"></span> {{objectAgency.acronym}}</div>
                                        <div style="width: 50%;">
                                            <div class="select-option btn-sm btn-sea-blue">{{therapeutic_area}}</div>                                
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-7">
                                    <div class="action-popup" style="text-align:right">
                                        <button class="btn btn-red" data-bs-dismiss="modal" aria-label="Close">CLOSE </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row row-ct px-1">
                            <div class="col-12 col-md-9 pe-0">                
                                <div class="bg-white main-graph">
                                
                                    <div class="box-graph" style="overflow: auto;">
                                        <table v-if="filterData && filterData.length > 0" class="table table-bordered table-fixed" style="font-size: 0.8rem;">
                                            <caption>Limitation landscape</caption>
                                            <tbody>
                                                <template v-for="(item, key) in list_limitation" :key="key" >                                                
                                                <tr>
                                                    <td width="200">{{ $t(item) }}</td>
                                                    <template v-for="(drug, key2) in filterData" :key="key2" >
                                                    <td v-if="hasAnyData(drug.economic_analysis)" class="text-center" width="120">
                                                        
                                                        <template v-if="hasData(drug.economic_analysis, item)">
                                                            <div class="cursor-pointer" @click="onShowDetail(drug.pk_id)" ><em class="flag size20 logo"></em></div>
                                                        </template>
                                                    </td>
                                                    </template>
                                                </tr>
                                                </template>
                                                <tr>
                                                    <td></td>
                                                    <template v-for="(drug, key2) in filterData" :key="key2" >
                                                    <td v-if="hasAnyData(drug.economic_analysis)" class="text-center" v-html="displayName(drug)"></td>
                                                    </template>

                                                </tr>
                                                
                                                
                                            </tbody>
                                        </table>
                                        <div v-else class="graph-nodata"> NO DATA</div> 
                                    </div>
                                </div>
                                

                                
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="mb-2"><strong>Drugs</strong></div>
                                <div class="wrap-box-line" >                        
                                
                                    <label v-for="(item, key) in list_name" :key="key"  class="list-bg-white box-bg-lightgray">
                                        <div>{{item}}</div>
                                        <div><input class="form-check-input" type="checkbox" :value="item" v-model="filter_name_temp"></div>
                                    </label>

                                    <div class="mb-2"><strong>Limitations</strong></div>
                                    <label v-for="(item, key) in list_limitation" :key="key"  class="list-bg-white box-bg-lightgray">
                                        <div>{{$t(item)}}</div>
                                        <div><input class="form-check-input" type="checkbox" :value="item" v-model="filter_limitation_temp"></div>
                                    </label>

                                    
                                </div>
                                <div class="button-fixed">
                                    <button class="btn btn-sea-blue btn-form" @click="handleFilter">Filter</button>
                                </div>
                            </div>
                            <div style="position: absolute;bottom: 13px;width: auto;">*Click on logo for more information</div>
                        </div>
                    </div>
                    
                </div>

            </div>
            <div class="modal-content border-blue" style="margin-top:6px" v-if="showDetail && dataDetail && dataDetail.acronym">
                <div  class="main-overlay"> 
                    <div class="action-popup" style="text-align:right">
                        <button class="btn btn-red" @click="showDetail=false">CLOSE </button>
                    </div>               
                    <table class="table table-basecase-modal border-left" >  
                        <caption>Limitation landscape detail</caption>                              
                        <tbody> 
                            <tr>
                                <td class="text-center" style="width:80px"> <div class="flag size35" :class="dataDetail.icon_flags"></div> <div>{{dataDetail.acronym}}</div></td> 
                                <td class="text-center" style="width:150px"><strong>{{dataDetail.category}}</strong> <div>{{ DDMMMYY(dataDetail.guide_date_num) }}</div></td> 
                                <td class="text-left"  v-html="dataDetail.title ? dataDetail.title.replace(/(&lt;([^>]+)>)/gi, '') : ''"> </td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="table table-basecase-modal border-left">      
                        <caption>Limitation landscape detail2</caption>                          
                        <tbody> 
                            <template v-for="(item, key) in list_limitation" :key="key">
                                <template v-for="(val, key2) in dataDetail.economic_analysis" :key="key2">
                                <tr v-if="val[item]" >
                                    <td class="text-left" style="width:180px">{{ $t(item)}} </td>  
                                    <td class="text-left" v-html="val[item]"> </td>
                                </tr>
                                </template>
                            </template>
                        </tbody>
                    </table>
 
                </div> 
    
            </div>
        </div>
    </div>
    </teleport>

</template>

<script>
import { ref, computed, watch, onMounted } from 'vue'
import { DDMMMYY } from '@/utils'
import { useStore } from 'vuex'
export default {
    components:{
    },
    props: {
        filter_parent: {
            type: Object,
            required: true
        },
        list_limitation : {            
            type: Array,
            required: true
        },
        data: {
            type: Array,
            required: true
        },
    },
    setup(props) {
        

        //----------------------- helper -------------------------- [start]        
        /**
         * Check if there is any information.
         * @property {Array} economic_analysis
         * @return {boolean}
         */
        const hasAnyData = (economic_analysis) => {
            let isFound = false
            props.list_limitation.forEach((field) => {
                if( economic_analysis.filter(val => val[field]).length){
                    isFound = true
                }
            })
            return isFound
        }
        /**
         * Check if there is any information.
         * @property {Array} economic_analysis
         * @property {String} field
         * @return {integer}
         */
        const hasData = (economic_analysis ,field) => {
            return economic_analysis.filter(val => val[field]).length
        }

         /**
         * helper display name
         * @property {Object} param
         * @return {String}
         */
        const displayName = (param) => {
            return `<div>${param.category}</div><div>${DDMMMYY(param.guide_date_num)}</div>`
        } 
        //----------------------- helper -------------------------- [end]
          

        
    

        const store = useStore()  
        /**
         * get data therapeutic_area
         * @return {String}
         */      
        const therapeutic_area = computed(() => {
            if(props.filter_parent && props.filter_parent.filter_therapeutic_area){
                return props.filter_parent.filter_therapeutic_area
            }else{
                return store.state.result.limitation_landscape_filter.therapeutic_area
            }
        })
        /**
         * get data agency
         * @return {Object}
         */
        const objectAgency = computed(() => {
            return {
                icon_flags : props.data.length ? props.data[0].icon_flags : "",
                acronym : props.data.length ? props.data[0].acronym : "",
            }
        });
        watch(() => props.data, () => {
            filter_name.value = []
            filter_limitation.value = []
            filter_name_temp.value = []
            filter_limitation_temp.value = []
        });
        
        
        
        //-------------------------- filter name ---------------------- 
        /**
         * get data name
         * @return {Object}
         */ 
        const list_name = computed(() => {
            let temp = []
            props.data.forEach((val) => {
                if(!temp.includes(val.category) && val.category){  
                    temp.push(val.category);    
                }             
            })
            return temp.sort()
        })      
        const filter_name = ref([])
        const filter_name_temp = ref([])


        //---------------------------- filter limitation -------------------------------
        const filter_limitation = ref([]);
        const filter_limitation_temp = ref([]);



        //-------------------------- handle click filter --------------------------[start]
        /**
         * handle click filter
         */ 
        const handleFilter = () => {
            console.log('handleFilter')
            filter_name.value = filter_name_temp.value
            filter_limitation.value = filter_limitation_temp.value
        }      
        /*
        * filter data Limitation landscape 
        */  
        const filterData = computed(() => {
            let temp = [];
            props.data.forEach((val) => {
                if(((filter_name.value.length && filter_name.value.includes(val.category)) || filter_name.value.length === 0) && val.economic_analysis.length){  
                    if(filter_limitation.value.length){
                        let has = false
                        filter_limitation.value.forEach((val2) => {
                            if( val.economic_analysis.filter(val3 => val3[val2]).length ){
                                has = true
                            }
                        });
                        if(has){
                            temp.push(val);   
                        }

                    }else{
                        temp.push(val);   
                    }                     
                }             
            })
            return temp
        });
        //-------------------------- handle click filter --------------------------[end]


        //----------------- detail limitation -------------------[start]
        const showDetail = ref(false);
        const dataDetail = ref({})      
        /**
         *  Onclick flag to set show detail 
         * @property {Integer} pk_id
         **/     
        const onShowDetail = (pk_id) => {             
            const temp = filterData.value.filter(i => i.pk_id == pk_id); 
            dataDetail.value = temp[0];
            showDetail.value = true
        }        

        /**
         *  set hide detail
         **/
        const setHideTableDetail = () => {
            showDetail.value = false             
        }
        //----------------- detail limitation -------------------[end]
               
        
        onMounted(() => {
            const myModalEl = document.getElementById('modal-limitation-main-country')
            myModalEl.addEventListener('hidden.bs.modal', setHideTableDetail)
        })

        return {filter_name, filter_limitation, list_name, handleFilter, hasData, filterData, displayName, therapeutic_area,
        filter_name_temp, filter_limitation_temp, objectAgency, hasAnyData, onShowDetail, showDetail, dataDetail, DDMMMYY}
    }
}
</script>
