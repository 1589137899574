<template>
    <div v-if="data.agency_model == 'Ceesp'" class="bg-white box-table-data-sub">        
        <table v-if="data.ceesp_titleappreserve" class="table table-bordered" >
            <caption>Rationale and comments</caption>
            <thead>
                <tr>
                    <td width="30%" >Analysis</td>
                    <td width="30%" >Decision</td>
                    <td width="40%" >Reservations</td>
                </tr> 
            </thead>
            <tbody>
                <tr v-for="(item, key) in data.ceesp_titleappreserve" :key="key"> 
                    <td>{{item['titleen']}}</td>
                    <td>{{$t('ceesp.appreciation.'+item['appreciation'])}}</td>
                    <td v-html="item['reverseen']"></td>
                </tr> 
            </tbody>
        </table>
        <div v-else style="padding: 5px;text-align: center;">No data</div>
            
    </div>
    <div v-else class="box-bg-gray p-3 box-list-text">
        <div class="row mb-1">
            <div class="col"><strong class="text-blue">{{ $t("Rationale and comments") }}</strong></div>
            <div class="col text-end">
                <div v-if="data.agency_type=='HTA/Reimbursement'">
                    <div v-if="data.hta_process_date"><strong>HTA date :</strong> <span class="text-secondary">{{DDMMMYY(data.hta_process_date)}}</span></div>
                    <div v-if="data.hta_decision"><strong>HTA decision :</strong> <span :class="color_rembursement(data.hta_decision)">{{$t('hta_decision.'+data.hta_decision).replace('hta_decision.', '')}}</span></div>
                    <div v-if="data.rembursement_process_date"><strong>Reimbursement date :</strong> <span class="text-secondary">{{DDMMMYY(data.rembursement_process_date)}}</span></div>
                    <div v-if="data.reimbursement_decision"><strong>Reimbursement status :</strong> <span :class="color_rembursement(data.reimbursement_decision)">{{$t('reimbursement_decision.'+data.reimbursement_decision).replace('reimbursement_decision.', '')}}</span></div>
                </div>
                <div v-else-if="data.agency_type=='Regulatory/Reimbursement'">
                    <div v-if="data.decision_date_num"><strong>Decision date :</strong> <span class="text-secondary">{{DDMMMYY(data.decision_date_num)}}</span></div>
                    <div v-if="data.decision"><strong>HTA decision :</strong> <span :class="color_rembursement(data.decision)">{{$t(data.decision)}}</span></div>
                    <div v-if="data.rembursement_process_date"><strong>Reimbursement date :</strong> <span class="text-secondary">{{DDMMMYY(data.rembursement_process_date)}}</span></div>
                    <div v-if="data.reimbursement_decision"><strong>Reimbursement status :</strong> <span :class="color_rembursement(data.reimbursement_decision)">{{$t('reimbursement_decision.'+data.reimbursement_decision).replace('reimbursement_decision.', '')}}</span></div>
                </div>
                <div v-else-if="data.agency_type=='Reimbursement'">
                    <div v-if="data.rembursement_process_date"><strong>Reimbursement date :</strong> <span class="text-secondary">{{DDMMMYY(data.rembursement_process_date)}}</span></div>
                    <div v-if="data.reimbursement_decision"><strong>Reimbursement status :</strong> <span :class="color_rembursement(data.reimbursement_decision)">{{$t('reimbursement_decision.'+data.reimbursement_decision).replace('reimbursement_decision.', '')}}</span></div>
                </div>
                <div v-else-if="data.agency_type=='HTA'">
                    <div v-if="data.hta_process_date"><strong>HTA date :</strong> <span class="text-secondary">{{DDMMMYY(data.hta_process_date)}}</span></div>
                    <div v-if="data.hta_decision"><strong>HTA decision :</strong> <span :class="color_rembursement(data.hta_decision)">{{$t('hta_decision.'+data.hta_decision).replace('hta_decision.', '')}}</span></div>
                </div>
                <div v-else>
                    <div v-if="data.decision_date_num"><strong>Decision date :</strong> <span class="text-secondary">{{DDMMMYY(data.decision_date_num)}}</span></div>
                    <div v-if="data.decision"><strong>HTA decision :</strong> <span :class="color_rembursement(data.decision)">{{$t(data.decision)}}</span></div>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table-info-text" v-if="data.prioritis_hta_index">
                <caption>prioritis hta index</caption>
                <tbody>
                    <tr>
                        <td class="text-left"><strong>{{$t('prioritis_hta_index')}}</strong></td>
                        <td class="text-left">{{$t(data.prioritis_hta_index)}}</td>
                    </tr>
                </tbody>
            </table>
            <table class="table-info-text" v-if="data.check_target_population || data.target_population_fr || data['target_population_'+$i18n.locale]" >
                <caption>target population</caption>
                <tbody>
                    <tr>
                        <td class="text-left"><strong>{{$t('target_population')}}</strong></td>
                        <td class="text-left"><font-awesome-icon v-if="data.check_target_population" icon="check" class="text-blue" /> <span v-html="data['target_population_'+$i18n.locale] ? data['target_population_'+$i18n.locale] : data.target_population_fr"></span> </td>
                    </tr>
                </tbody>
            </table>
            <table class="table-info-text" v-if="data.check_prescription_and_delivery_conditions || data.prescription_and_delivery_conditions_fr || data['prescription_and_delivery_conditions_'+$i18n.locale]" >
                <caption>prescription and delivery conditions</caption>
                <tbody>
                    <tr>
                        <td class="text-left"><strong>{{$t('prescription_and_delivery_conditions')}}</strong></td>
                        <td class="text-left"><font-awesome-icon v-if="data.check_prescription_and_delivery_conditions" icon="check" class="text-blue" /> <span v-html="data['prescription_and_delivery_conditions_'+$i18n.locale] ? data['prescription_and_delivery_conditions_'+$i18n.locale] : data.prescription_and_delivery_conditions_fr"></span> </td>
                    </tr>
                </tbody>
            </table>
            <table class="table-info-text" v-if="data.check_access_scheme || data.access_scheme_fr || data['access_scheme_'+$i18n.locale]" >
                <caption>access scheme</caption>
                <tbody>
                    <tr>
                        <td class="text-left"><strong>{{$t('access_scheme')}}</strong></td>
                        <td class="text-left"><font-awesome-icon v-if="data.check_access_scheme" icon="check" class="text-blue" /> <span v-html="data['access_scheme_'+$i18n.locale] ? data['access_scheme_'+$i18n.locale] : data.access_scheme_fr"></span> </td>
                    </tr>
                </tbody>
            </table>
            <table class="table-info-text" v-if="data.check_outcome_level || data.outcome_level_fr || data['outcome_level_'+$i18n.locale]" >
                <caption>outcome level</caption>
                <tbody>
                    <tr>
                        <td class="text-left"><strong>{{$t('outcome_level')}}</strong></td>
                        <td class="text-left"><font-awesome-icon v-if="data.check_outcome_level" icon="check" class="text-blue" /> <span v-html="data['outcome_level_'+$i18n.locale] ? data['outcome_level_'+$i18n.locale] : data.outcome_level_fr"></span> </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-2" style="padding-left: 1.5rem;">
            <div v-html="rationale_commentary"></div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { DDMMMYY, color_rembursement } from '@/utils'
export default {
    
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    setup(props) {

        const { locale } = useI18n()
        /**
         * get comments
         */
        const rationale_commentary = computed(() => {
            let rationale_commentary = ''
            if (['fr', 'en', 'de'].includes(locale.value)) {
                rationale_commentary = props.data['rationale_and_comments_html_' + locale.value]
            } else {
                rationale_commentary = props.data['rationale_and_comments_html_original']
            }

            if (!rationale_commentary) {
                rationale_commentary = props.data['rationale_and_comments_html_en'];
            }
            return rationale_commentary
        })
        
        
        return {DDMMMYY, color_rembursement, rationale_commentary}
    }
}
</script>