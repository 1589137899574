<template>
    <div class="bg-white box-table-sub">
                                   
        <table class="table table-bordered" >
            <caption>Table Introduction</caption>
            <tbody>
                <tr v-if="data.decision">
                    <td style="width: 25%;">Decision</td>
                    <td style="width: 75%;" v-html="data.decision"></td>
                </tr>
                <tr v-if="data.guide_date">
                    <td style="width: 25%;">Guidance date</td>
                    <td style="width: 75%;">{{DDMMMYY(data.guide_date)}}</td>
                </tr>
                <tr v-if="data.title">
                    <td style="width: 25%;">Indication</td>
                    <td style="width: 75%;" v-html="data.title"></td>
                </tr>
                <tr v-if="data.goal_intervention">
                    <td style="width: 25%;">Goal/intervention</td>
                    <td style="width: 75%;" v-html="data.goal_intervention"></td>
                </tr>
                <tr v-if="data.type_of_request">
                    <td style="width: 25%;">Type of request</td>
                    <td style="width: 75%;" v-html="data.type_of_request"></td>
                </tr>
                <tr v-if="data.type_of_economic_analysis">
                    <td style="width: 25%;">Type of economic analysis</td>
                    <td style="width: 75%;" v-html="$t(data.type_of_economic_analysis)"></td>
                </tr>
                <tr v-if="data.health_outcome">
                    <td style="width: 25%;">Health outcome</td>
                    <td style="width: 75%;" v-html="data.health_outcome"></td>
                </tr>                                      
            </tbody>
        </table>
        
        <div class="button-fixed">
            <button type="button" class="btn">Introduction</button>
        </div>
    </div>  
</template>

<script>

import { DDMMMYY } from '@/utils'
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    setup() {
        
        return {DDMMMYY}
    }
}
</script>