<template>
     
    <div class="flex">
        <h4>Base case :</h4>
        <div class="action-popup" style="margin-left: auto;">
            <button class="btn bg-blue" v-if="0">PDF</button>  <button class="btn btn-red" data-bs-dismiss="modal" aria-label="Close">CLOSE </button>
        </div>
    </div>
    <div v-if="data && data.length" class="content-modal-basecase">
        <div class="box-left">                    
            <div class="flag size35" :class="data[0].icon_flags"></div>
            <div>{{data[0].acronym}}</div>
        </div>
        <div class="box-right">
            <div class="table-responsive">
                <table class="table table-borderless table-basecase-modal" :id="idTable" style="table-layout: fixed" >
                    <caption>Base case detail</caption>
                    <colgroup>
                        <col style="width:100px">
                        <col style="width:250px">
                        <col style="width:250px">
                        <col style="width:100px">
                        <col v-if="hasMinMax" style="width:100px">
                        <col v-if="hasMinMax" style="width:100px">
                        <col style="width:250px">
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope="col" >Base case</th>
                            <th scope="col" >Line, subgroup</th>
                            <th scope="col" >Comparator (Latest undominated comparator)</th>
                            <th scope="col" >ICER</th>
                            <th scope="col" v-if="hasMinMax">Confidence interval(Min)</th>
                            <th scope="col" v-if="hasMinMax">Confidence interval(Max)</th>
                            <th scope="col" >Comment</th>
                        </tr>
                    </thead>
                    <!--   -->
                    <tbody>
                        <template v-for="(item, key) in tableData" :key="key">
                            <tr>
                                <td class="td-bg-gray" :colspan="hasMinMax ? 7 : 5" ><strong>{{item.category}}</strong> / {{ DDMMMYY(item.guide_date_num) }} <span v-html="item.title ? '/ '+item.title.replace(/(&lt;([^>]+)>)/gi, '') : ''"></span> </td>
                            </tr>
                            <tr>
                                <td class="p-0" :colspan="hasMinMax ? 7 : 5" >
                                    <table class="table-sub" style="table-layout: fixed;" >
                                        <caption>Base case sub detail</caption>
                                        <colgroup>
                                            <col style="width:100px">
                                            <col style="width:250px">
                                            <col style="width:250px">
                                            <col style="width:100px">
                                            <col v-if="hasMinMax" style="width:100px">
                                            <col v-if="hasMinMax" style="width:100px">
                                            <col style="width:250px">
                                        </colgroup>
                                        <tbody>
                                            <tr v-for="(val, k) in item.base_case" :key="k">
                                                <td>{{ checkEmpty(val.source_of_analysis) }}</td>
                                                <td>{{ checkEmpty(val.line_subgroup) }}</td>
                                                <td>{{ checkEmpty(val.comparator) }}</td>                                           
                                                <td>{{ icer_icur(val.icer_icur, val.exceed, val.less, val.na, val.dominated, val.reference, "-") }}</td>
                                                <td v-if="hasMinMax" >{{ checkEmpty(val.range_min) }}</td>
                                                <td v-if="hasMinMax" >{{ checkEmpty(val.range_max) }}</td>                                            
                                                <td>{{ checkEmpty(val.comment) }}</td> 
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>                                
                            </tr>
                        </template> 
                    </tbody>
                </table>
            </div>
        </div>
    </div>
                 
            
</template>
<script>
import { computed } from 'vue' 
import { DDMMMYY, checkEmpty, icer_icur } from '@/utils'
export default {
    props: {
        data: {
            type: Array,
            required: true
        },
        idTable:String
    },
    setup(props){
        /**
         * fetch data
         */
        const tableData =  computed(() => {
            let data = [];
            let tempID = 0;
            let tempData = {};
            if(props.data.length){
                props.data.forEach((item) => {   
                    console.log(item.economic_evaluation_id)  
                    if(tempID === item.economic_evaluation_id){
                        tempData['base_case'].push(item)
                    }else{
                        if(tempData.category){
                            data.push(tempData)
                        }
                        tempID = item.economic_evaluation_id;
                        tempData = {}
                        tempData['category'] = item.category;
                        tempData['guide_date_num'] = item.guide_date_num;
                        tempData['title'] = item.title;      
                        tempData['base_case'] = [];
                        tempData['base_case'].push(item)
                    }             
                })
            }
            if(tempData.category){
                data.push(tempData)
            }
            return data;
        });

        const hasMinMax = computed(() => {
            if(props.data.length){
                return props.data.filter(val => val.range_min).length  
            }
            return false
        })


        return{ hasMinMax, DDMMMYY, checkEmpty, icer_icur, tableData }
    }
}
</script>
