<template>
    <div class="bg-white box-table-data-sub">
                                   
        <table class="table table-bordered">
            <caption>Base case</caption>
            <thead>
                <tr>
                    <td style="width:6%">Base case</td>
                    <td style="width:15%">Line, subgroup</td>
                    <td style="width:15%">Comparator (Latest undominated comparator)</td>
                    <td style="width:6%">Total costs</td>                                        
                    <td style="width:6%">Incremental costs</td>
                    <td style="width:6%">Total outcomes</td>
                    <td style="width:6%">Incremental outcomes </td>
                    <template v-if="data.type_of_economic_analysis == 'cea' ">
                        <td style="width:6%">ICER</td>
                        <td style="width:6%">Confidence interval(Min)</td>
                        <td style="width:6%">Confidence interval(Max)</td>
                    </template>
                    <template v-else-if="data.type_of_economic_analysis == 'cua' ">
                        <td style="width:6%">ICUR</td>
                        <td style="width:6%">Confidence interval(Min)</td>
                        <td style="width:6%">Confidence interval(Max)</td>
                    </template>
                    <template v-else-if="data.type_of_economic_analysis == 'cma' ">
                        <td style="width:6%">Cost savings</td>
                        <td style="width:6%">Total cost</td>
                        <td style="width:6%">Net monetary benefit</td>
                        <td style="width:6%">Net monetary loss</td>
                    </template>
                    <td style="width:22.5%">Comment</td>
                </tr>
            </thead>
            <tbody>               
                <template v-if="data.economic_base_case">
                    <tr v-for="(item, key) in data.economic_base_case" :key="key">
                        <td>{{ checkEmpty(item.source_of_analysis) }}</td>
                        <td>{{ checkEmpty(item.line_subgroup) }}</td>
                        <td>{{ checkEmpty(item.comparator) }}</td>
                        <td>{{ checkEmpty(item.total_costs) }}</td>
                        <td>{{ checkEmpty(item.incremental_costs) }}</td>
                        <td>{{ checkEmpty(item.total_outcomes) }}</td>
                        <td>{{ checkEmpty(item.incremental_outcomes) }}</td>
                        <template v-if="data.type_of_economic_analysis == 'cea' ">
                            <td>{{ icer_icur(item.icer, item.exceed, item.less, item.na, item.dominated, item.reference, "-") }}</td>
                            <td>{{ checkEmpty(item.range_min) }}</td>
                            <td>{{ checkEmpty(item.range_max) }}</td>
                        </template>
                        <template v-else-if="data.type_of_economic_analysis == 'cua' ">
                            <td>{{ icer_icur(item.icur, item.exceed, item.less, item.na, item.dominated, item.reference, "-") }}</td>
                            <td>{{ checkEmpty(item.range_min) }}</td>
                            <td>{{ checkEmpty(item.range_max) }}</td>
                        </template>
                        <template v-else-if="data.type_of_economic_analysis == 'cma' ">
                            <td>{{ checkEmpty(item.cost_savings) }}</td>
                            <td>{{ checkEmpty(item.total_cost) }}</td>
                            <td>{{ checkEmpty(item.net_monetary_benefit) }}</td>
                            <td>{{ checkEmpty(item.net_monetary_loss) }}</td>
                        </template>
                        <td>{{ checkEmpty(item.comment) }}</td>
                    </tr>
                </template>
                                                   
            </tbody>
        </table>
        
        <div class="button-fixed">
            <button type="button" class="btn">Base case</button>
        </div>
    </div>  
</template>

<script>
import { checkEmpty, icer_icur } from '@/utils'
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    setup(){

        return {checkEmpty, icer_icur}
    }
}
</script>