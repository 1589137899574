<template>
    <div class="bg-white box-table-sub">
        <template v-for="(item, key)  in data.economic_analysis" :key="key">    
            <template v-if="globalAccess.includes('analytic_framework') || !hasGlobalAccess">                     
                <table class="table table-bordered" >  
                    <caption>Analytical framework</caption>          
                    <tr class="cursor-pointer" data-bs-toggle="collapse" :data-bs-target="`#Analyticframwork_${data.id}_${item.id}`" aria-expanded="false" >
                        <td class="text-left"><strong class="text-blue">Analytical framework</strong> 
                        <span class="span-chevron-down" ><font-awesome-icon icon="chevron-down" :style="{fontSize : '10px', padding : 0}" /></span>
                        <span class="span-chevron-up" ><font-awesome-icon icon="chevron-up" :style="{fontSize : '10px', padding : 0}" /></span>
                        </td>                    
                    </tr>
                </table>
                <div class="collapse" :id="`Analyticframwork_${data.id}_${item.id}`">
                    <table class="table table-bordered" >
                        <caption>Analytical framework detail</caption>
                        <tbody>
                            <tr v-if="item.method_patients_population_ma_population || item.method_patients_population_evidence_based_documented_population || 
                            item.method_patients_population_simulated_population">
                                <td colspan="2">Patient population (indication, line, subgroup)</td>
                            </tr>
                            <tr v-if="item.method_patients_population_ma_population">
                                <td class="sub-list">MA population</td>
                                <td v-html="item.method_patients_population_ma_population"></td>
                            </tr>
                            <tr v-if="item.method_patients_population_evidence_based_documented_population">
                                <td class="sub-list">Evidence based documented population</td>
                                <td v-html="item.method_patients_population_evidence_based_documented_population"></td>
                            </tr>
                            <tr v-if="item.method_patients_population_simulated_population">
                                <td class="sub-list">Simulated population</td>
                                <td v-html="item.method_patients_population_simulated_population"></td>
                            </tr>
                            <tr v-if="item.method_perspective">
                                <td>Perspective</td>
                                <td v-html="item.method_perspective"></td>
                            </tr>
                            <tr v-if="item.method_time_horizon">
                                <td>Time horizon</td>
                                <td v-html="item.method_time_horizon"></td>
                            </tr>
                            <tr v-if="item.method_cycle_lenth">
                                <td>Cycle length</td>
                                <td v-html="item.method_cycle_lenth"></td>
                            </tr>
                            <tr v-if="item.method_intervention">
                                <td>Intervention</td>
                                <td v-html="item.method_intervention"></td>
                            </tr>
                            <template v-if="item.framework_comparator">
                            <tr>
                                <td colspan="2">Comparators </td>
                            </tr>
                            <tr v-for="(v, k) in item.framework_comparator" :key="k">
                                <td class="sub-list">Comparators {{k+1}}</td>
                                <td v-html="v"></td>
                            </tr>
                            </template>
                            <tr v-if="item.discount_rate_effects || item.discount_rate_costs">
                                <td colspan="2">Annual discount rate</td>
                            </tr>
                            <tr v-if="item.discount_rate_effects">
                                <td class="sub-list">Effects</td>
                                <td>{{ item.discount_rate_effects + "%" }}</td>
                            </tr>
                            <tr v-if="item.discount_rate_costs">
                                <td class="sub-list">Costs</td>
                                <td>{{ item.discount_rate_costs + "%" }}</td>
                            </tr>
                            <tr v-if="item.choice_of_model">
                                <td>Choice of model</td>
                                <td v-html="item.choice_of_model"></td>
                            </tr>
                            <tr v-if="item.administration_dosage">
                                <td>Administration / Dosage</td>
                                <td v-html="item.administration_dosage"></td>
                            </tr>
                            <template v-if="item.framework_assumptions">
                            <tr>
                                <td colspan="2">Assumptions </td>
                            </tr>
                            <tr v-for="(v, k) in item.framework_assumptions" :key="k">
                                <td class="sub-list">Assumptions {{k+1}}</td>
                                <td v-html="v"></td>
                            </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </template>

            <template v-if="item.structure_model && (globalAccess.includes('structure_model') || !hasGlobalAccess)">
                <table class="table table-bordered" > 
                    <caption>Structure of the model</caption>           
                    <tr class="cursor-pointer" data-bs-toggle="collapse" :data-bs-target="`#structure-model_${data.id}_${item.id}`" aria-expanded="false" >
                        <td class="text-left"><strong class="text-blue">Structure of the model</strong> 
                        <span class="span-chevron-down" ><font-awesome-icon icon="chevron-down" :style="{fontSize : '10px', padding : 0}" /></span>
                        <span class="span-chevron-up" ><font-awesome-icon icon="chevron-up" :style="{fontSize : '10px', padding : 0}" /></span>
                        </td>                    
                    </tr>
                </table>   
                <div class="collapse" :id="`structure-model_${data.id}_${item.id}`">
                    <table class="table table-bordered">
                        <caption>Structure of the model detail</caption>
                        <tbody>
                            <tr>
                                <td width="20%">Structure of the model <div v-if="item.image" @click="loadPicture=true" data-bs-toggle="collapse" :data-bs-target="`#structure-model-image_${data.id}_${item.id}`" aria-expanded="false" style="text-decoration: underline; cursor: pointer">See picture</div></td>
                                <td v-html="item.structure_model"></td>
                            </tr> 
                            <tr v-if="item.image" class="collapse" :id="`structure-model-image_${data.id}_${item.id}`">
                                <td>Model picture</td>                                                
                                <td>
                                    <div v-if="loadPicture">                                    
                                        <a :href="`/share/uploads/EconomicEvaluationAnalysis/${item.id}/image.${type_file(item.image_name)}`" target="_blank">
                                            <img :src="`/share/uploads/EconomicEvaluationAnalysis/${item.id}/image.${type_file(item.image_name)}`" style="max-height:260px" alt="" >
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>

            <template v-if="(item.review_of_literature_for_comparators || item.meta_analysis_method_for_comparators || item.comment
             || item.review_of_literature_for_comparators || item.nct_numbers) && (globalAccess.includes('measurement') || !hasGlobalAccess)">
               
                <table class="table table-bordered" > 
                    <caption>Measurement of effectiveness</caption>           
                    <tr class="cursor-pointer" data-bs-toggle="collapse" :data-bs-target="`#Measurement_${data.id}_${item.id}`" aria-expanded="false" >
                        <td class="text-left"><strong class="text-blue">Measurement of effectiveness</strong> 
                        <span class="span-chevron-down" ><font-awesome-icon icon="chevron-down" :style="{fontSize : '10px', padding : 0}" /></span>
                        <span class="span-chevron-up" ><font-awesome-icon icon="chevron-up" :style="{fontSize : '10px', padding : 0}" /></span>
                        </td>                    
                    </tr>
                </table>  
                <div class="collapse" :id="`Measurement_${data.id}_${item.id}`">                    
                    <EssaisClinique v-if="item.nct_numbers" :id="item.id" :pk_id="parseInt(data.pk_id)" />
                    <table v-else class="table table-bordered" >
                        <caption>Measurement of effectiveness detail</caption>
                        <tbody>
                            <tr v-if="item.review_of_literature_for_comparators">
                                <td>Literature review for the intervention/comparators</td>
                                <td v-html="item.review_of_literature_for_comparators"></td>
                            </tr>
                            <tr v-if="item.meta_analysis_method_for_comparators">
                                <td>Meta-analysis method for comparators</td>
                                <td v-html="item.meta_analysis_method_for_comparators"></td>
                            </tr>
                            <tr v-if="item.comment">
                                <td>Comment</td>
                                <td v-html="item.comment"></td>
                            </tr>
                            <tr v-if="item.review_of_literature_for_comparators">
                                <td>Literature review for the intervention/comparators</td>
                                <td v-html="item.review_of_literature_for_comparators"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>


            <template v-if="item.extrapolation && (globalAccess.includes('extrapolation') || !hasGlobalAccess)">
                <table class="table table-bordered">         
                    <caption>Extrapolation</caption>   
                    <tr class="cursor-pointer" data-bs-toggle="collapse" :data-bs-target="`#extrapolation_${data.id}_${item.id}`" aria-expanded="false" >
                        <td class="text-left"><strong class="text-blue">Extrapolation</strong>
                        <span class="span-chevron-down" ><font-awesome-icon icon="chevron-down" :style="{fontSize : '10px', padding : 0}" /></span>
                        <span class="span-chevron-up" ><font-awesome-icon icon="chevron-up" :style="{fontSize : '10px', padding : 0}" /></span>
                        </td>                    
                    </tr>
                </table>  
                <div class="collapse" :id="`extrapolation_${data.id}_${item.id}`">

                    <template v-for="(val,k) in item.extrapolation" :key="k">
                        <table class="table table-bordered">
                            <caption>Extrapolation detail</caption>
                            <tbody>        
                                <tr class="cursor-pointer" data-bs-toggle="collapse" :data-bs-target="`#extrapolation_${data.id}_${item.id}_${k}`" aria-expanded="false" style="background-color:#ceecbb;" >
                                    <td>Endpoint <font-awesome-icon icon="chevron-down" :style="{fontSize : '10px', padding : 0}" /></td>   
                                    <td v-html="val.endpoint"></td>         
                                </tr>
                            </tbody>
                        </table>  
                        <div class="collapse" :id="`extrapolation_${data.id}_${item.id}_${k}`">
                            <table class="table table-bordered">
                                <caption>Extrapolation sub detail</caption>
                                <tbody>
                                    <tr>
                                        <td width="15%"></td>
                                        <td width="35%"><strong>Company</strong></td>
                                        <td width="35%"><strong>Agency</strong></td>
                                    </tr>
                                    <tr v-if="val.method || val.agency_preferred_method">
                                        <td>Method</td>
                                        <td v-html="val.method"></td>
                                        <td v-html="val.agency_preferred_method"></td>
                                    </tr>
                                    <tr v-if="val.source || val.agency_preferred_source">
                                        <td>Source</td>
                                        <td v-html="val.source"></td>
                                        <td v-html="val.agency_preferred_source"></td>
                                    </tr>
                                    <tr v-if="val.comments || val.agency_comments">
                                        <td>Comment</td>
                                        <td v-html="val.comments"></td>
                                        <td v-html="val.agency_comments"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template> 
                </div>
            </template>

            <template v-if="item.utility_value && (globalAccess.includes('utility_values') || !hasGlobalAccess)">
                <table class="table table-bordered">     
                    <caption>Utility values</caption>       
                    <tr class="cursor-pointer" data-bs-toggle="collapse" :data-bs-target="`#utility_value_${data.id}_${item.id}`" aria-expanded="false" >
                        <td class="text-left"><strong class="text-blue">Utility values</strong> 
                        <span class="span-chevron-down" ><font-awesome-icon icon="chevron-down" :style="{fontSize : '10px', padding : 0}" /></span>
                        <span class="span-chevron-up" ><font-awesome-icon icon="chevron-up" :style="{fontSize : '10px', padding : 0}" /></span>
                        </td>                    
                    </tr>
                </table>  
                <div class="collapse" :id="`utility_value_${data.id}_${item.id}`">
                    <table class="table table-bordered">
                        <caption>Utility values detail</caption>
                        <tbody>
                            <template v-for="(val, k) in item.utility_value" :key="k">
                                <tr v-if="val.preference_scoring_method">
                                    <td>Preference scoring method</td>
                                    <td v-html="val.preference_scoring_method"></td>
                                </tr>
                                <tr v-if="val.sources">
                                    <td>Sources</td>
                                    <td v-html="val.sources"></td>
                                </tr>
                                <tr v-if="val.utility_comment">
                                    <td>Comment</td>
                                    <td v-html="val.utility_comment"></td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </template>

            <template v-if="(item.resources || item.resources_sources || item.type_of_costs
             || item.type_of_costs_sources || item.currency_and_price_date_date || item.currency_and_price_date_currency)
              && (globalAccess.includes('resources_costs') || !hasGlobalAccess)">              
                <table class="table table-bordered">    
                    <caption>Resources and costs</caption>        
                    <tr class="cursor-pointer" data-bs-toggle="collapse" :data-bs-target="`#resources_costs_${data.id}_${item.id}`" aria-expanded="false" >
                        <td class="text-left"><strong class="text-blue">Resources and costs</strong> 
                        <span class="span-chevron-down" ><font-awesome-icon icon="chevron-down" :style="{fontSize : '10px', padding : 0}" /></span>
                        <span class="span-chevron-up" ><font-awesome-icon icon="chevron-up" :style="{fontSize : '10px', padding : 0}" /></span>
                        </td>                    
                    </tr>
                </table>  
                <div class="collapse" :id="`resources_costs_${data.id}_${item.id}`">
                    <table class="table table-bordered">
                        <caption>Resources and costs detail</caption>
                        <tbody>
                            <tr v-if="item.resources">
                                <td>Resources</td>
                                <td v-html="item.resources"></td>
                            </tr>
                            <tr v-if="item.resources_sources">
                                <td>Sources</td>
                                <td v-html="item.resources_sources"></td>
                            </tr>
                            <tr v-if="item.type_of_costs">
                                <td>Type of costs</td>
                                <td v-html="item.type_of_costs"></td>
                            </tr>
                            <tr v-if="item.type_of_costs_sources">
                                <td>Sources</td>
                                <td v-html="item.type_of_costs_sources"></td>
                            </tr>
                            <tr v-if="item.currency_and_price_date_date || item.currency_and_price_date_currency">
                                <td colspan="2">Currency and price date</td>   
                            </tr>
                            <tr v-if="item.currency_and_price_date_date">
                                <td class="sub-list">Date of collection</td>
                                <td>{{ DDMMMYY(item.currency_and_price_date_date) }}</td>
                            </tr>
                            <tr v-if="item.currency_and_price_date_currency">
                                <td class="sub-list">Currency</td>
                                <td>{{ item.currency_and_price_date_currency }}</td>
                            </tr> 
                        </tbody>
                    </table>
                </div>
            </template>

            <template v-if="(item.structural_sensibility_analysis || item.deterministic_sensibility_analysis || item.probabilistic_sensibility_analysis
             || item.scenario_analysis) && (globalAccess.includes('sensitivity_analysis') || !hasGlobalAccess)">              
                <table class="table table-bordered">
                    <caption>Sensitivity analysis</caption>      
                    <tr class="cursor-pointer" data-bs-toggle="collapse" :data-bs-target="`#sensitivity_analysis_${data.id}_${item.id}`" aria-expanded="false" >
                        <td class="text-left"><strong class="text-blue">Sensitivity analysis</strong> 
                        <span class="span-chevron-down" ><font-awesome-icon icon="chevron-down" :style="{fontSize : '10px', padding : 0}" /></span>
                        <span class="span-chevron-up" ><font-awesome-icon icon="chevron-up" :style="{fontSize : '10px', padding : 0}" /></span>
                        </td>                    
                    </tr>
                </table>  
                <div class="collapse" :id="`sensitivity_analysis_${data.id}_${item.id}`">
                    <table class="table table-bordered">
                        <caption>Sensitivity analysis detail</caption>
                        <tbody>
                            <tr v-if="item.structural_sensibility_analysis">
                                <td>Structural sensibility analysis</td>
                                <td>Yes</td>
                            </tr>
                            <tr v-if="item.deterministic_sensibility_analysis">
                                <td>Deterministic sensibility analysis (Tornado)</td>
                                <td>Yes</td>
                            </tr>
                            <tr v-if="item.probabilistic_sensibility_analysis">
                                <td>Probabilistic sensibility analysis</td>
                                <td>Yes</td>
                            </tr>
                            <tr v-if="item.scenario_analysis">
                                <td>Scenario analysis</td>
                                <td>Yes</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>


        </template>



        <div class="button-fixed">
            <button type="button" class="btn">Method</button>
        </div>
    </div>
   
  
</template>

<script>
import { computed, ref } from 'vue'
import { DDMMMYY, type_file } from '@/utils'
import { useStore } from 'vuex'
import EssaisClinique from '@/components/result/EssaisClinique.vue'
export default {
    components :{
        EssaisClinique
    },
    props: {
        data: {
            type: Object,
            required: true
        },
    },
    setup() {        
        const store = useStore() 
        const globalAccess = computed(() => store.state.view_selection.method)
        const hasGlobalAccess = computed(() => store.state.view_selection.method.length)
        const loadPicture = ref(false)
        return {DDMMMYY, hasGlobalAccess, globalAccess, type_file, loadPicture}
    }
}
</script>

