<template>
    <div class="bg-white box-table-data-sub">                           
        <table class="table table-bordered" >  
            <caption>Table Reservations</caption>          
            <thead>
                <tr>
                    <th scope="col" width="70%">Reservations</th>
                    <th scope="col" width="10%">Minor</th>
                    <th scope="col" width="10%">Important</th>
                    <th scope="col" width="10%">Major</th>
                </tr>
            </thead>
            <tbody>

                <tr><td class="text-left" colspan="4"><strong>Efficiency</strong></td></tr>
                <template v-for="(item, key) in data.ceesp_reserves" :key="key">
                    <template v-for="(item2, key2) in item" :key="key2">
                        <template v-if="item2">
                        <tr><td colspan="4" class="text-left" ><strong>{{$t("ceesp."+key2)}}</strong></td></tr>    
                        <tr v-for="(item3, key3) in item2" :key="key3">
                            <td class="text-left" style="padding-left:15px" v-html="item3.text_en"></td>
                            <td>{{item3.item == "-" ? item3.item : "" }}</td>
                            <td>{{item3.item == "+" ? item3.item : "" }}</td>
                            <td>{{item3.item == "++" ? item3.item : "" }}</td>
                        </tr>
                        </template>
                    </template>
                </template>
            </tbody>
        </table>
        
        <div class="button-fixed">
            <button type="button" class="btn">Reservations</button>
        </div>
    </div>  
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
     
}
</script>
