<template>
<teleport to="body">
    <div class="modal fade modal-basecase" id="modal-limitation" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-blue">
                <div class="flex">
                    <h4>Limitations : {{ $t(field) }}</h4>
                    <div class="action-popup" style="margin-left: auto;">
                        <button class="btn btn-red" data-bs-dismiss="modal" aria-label="Close">CLOSE </button>
                    </div>
                </div>
                 <!-- {{data}} -->
                <template v-if="data && data.length" >
                <div v-for="(item, key) in data" :key="key" class="content-modal-basecase" style=" padding: 5px 15px;">
                    <div class="box-left">                    
                        <div class="flag size35" :class="item[0].icon_flags"></div>
                        <div>{{item[0].acronym}}</div>
                    </div>
                    <div class="box-right">
                        <div class="table-responsive">
                            <table class="table table-basecase-modal border-left table-borderless mb-0" >  
                                <caption>Base case detail</caption>
                                <tbody>
                                    <template v-for="(item2, key2) in item" :key="key2">
                                        <template v-if="hasData(item2.economic_analysis)">
                                        
                                        <tr>
                                            <td class="text-left td-bg-gray"><strong>{{item2.category}}</strong> / {{ DDMMMYY(item2.guide_date_num) }} <span v-html="item2.title ? '/ '+item2.title.replace(/(&lt;([^>]+)>)/gi, '') : ''"></span> </td>
                                        </tr>
                                        <template v-for="(item3, key3) in item2.economic_analysis" :key="key3">
                                            <tr class="tr-gray2" v-if="item3[field]">
                                                <td class="text-left" v-html="item3[field]"></td>                                             
                                            </tr>
                                        </template> 
                                        </template> 
                                    </template> 
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </template> 
            
            </div>
        </div>
    </div>
</teleport>
</template>
<script>
import { DDMMMYY } from '@/utils' 
export default {   
    props: {
        data: {
            type: Array,
            required: true
        },
        field: {
            type: String,
            required: true
        },
    },
    setup(props){ 
        /**
         * Check if there is any information.
         * @property {Array} param
         * @return {boolean}
        */ 
        const hasData = (param) => {
            let isFound = false;
            param.forEach((val) => {
                if(val[props.field]){
                    isFound = true
                }
            })
            return isFound
        }       
         
        return{ DDMMMYY, hasData }
    }
}
</script>
