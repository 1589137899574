<template>
    <tr class="tr-darkgray" v-if="hasData.base_case || hasData.scenario || hasData.deterministic || hasData.probabilistic || hasData.sensitivity || hasData.chart_base_case || hasData.chart_scenario || hasData.private" >
        <td colspan="9">
            <div class="box-switch">
                <strong v-if="hasData.base_case || hasData.scenario || hasData.deterministic || hasData.probabilistic || hasData.sensitivity">Table data</strong> 
                <div class="form-check form-switch from-check-ct" v-if="hasData.base_case">
                    <input class="form-check-input" type="checkbox" v-model="localAccessTable['base_case']" :id="`checkBoxTableBaseCase${data.pk_id}`">
                    <label class="form-check-label" :for="`checkBoxTableBaseCase${data.pk_id}`">Base case</label>
                </div> 
                <div class="form-check form-switch from-check-ct" v-if="hasData.scenario">
                    <input class="form-check-input" type="checkbox" v-model="localAccessTable['scenario']" :id="`checkBoxTableScenario${data.pk_id}`">
                    <label class="form-check-label" :for="`checkBoxTableScenario${data.pk_id}`">Scenario analysis</label>
                </div> 
                <div class="form-check form-switch from-check-ct" v-if="hasData.deterministic">
                    <input class="form-check-input" type="checkbox" v-model="localAccessTable['deterministic']" :id="`checkBoxTableDeterministic${data.pk_id}`">
                    <label class="form-check-label" :for="`checkBoxTableDeterministic${data.pk_id}`">Deterministic sensitivity analysis</label>
                </div> 
                <div class="form-check form-switch from-check-ct" v-if="hasData.probabilistic">
                    <input class="form-check-input" type="checkbox" v-model="localAccessTable['probabilistic']" :id="`checkBoxTableProbabilistic${data.pk_id}`">
                    <label class="form-check-label" :for="`checkBoxTableProbabilistic${data.pk_id}`">Probabilistic sensitivity analysis</label>
                </div> 
                <div class="form-check form-switch from-check-ct" v-if="hasData.sensitivity">
                    <input class="form-check-input" type="checkbox" v-model="localAccessTable['sensitivity']" :id="`checkBoxTableSensitivity${data.pk_id}`">
                    <label class="form-check-label" :for="`checkBoxTableSensitivity${data.pk_id}`">Sensitivity analysis</label>
                </div> 
                <div v-if="hasData.chart_base_case || hasData.chart_scenario" style="display: inline-block;">
                <strong>|</strong> 
                <strong>Graph data</strong>
                </div>
                <div class="form-check form-switch from-check-ct" v-if="hasData.chart_base_case">
                    <input class="form-check-input" type="checkbox" v-model="localAccessGraph['base_case']" :id="`checkBoxGraphBaseCase${data.pk_id}`">
                    <label class="form-check-label" :for="`checkBoxGraphBaseCase${data.pk_id}`">Base case</label>
                </div> 
                <div class="form-check form-switch from-check-ct" v-if="hasData.chart_scenario">
                    <input class="form-check-input" type="checkbox" v-model="localAccessGraph['scenario']" :id="`checkBoxGraphScenario${data.pk_id}`">
                    <label class="form-check-label" :for="`checkBoxGraphScenario${data.pk_id}`">Scenario analysis</label>
                </div>



                <div class="form-check form-switch from-check-ct" v-if="hasData.private">
                    <input class="form-check-input" type="checkbox" v-model="localAccessResult" :id="`localAccessResult${data.pk_id}`">
                    <label class="form-check-label" :for="`localAccessResult${data.pk_id}`">Results</label>
                </div>   

            </div>    
        </td>
    </tr>
    <tr v-if="localAccessTable.base_case" class="tr-darkgray">
        <td colspan="9"> 
            <TableDataBaseCase :data="data"  />                       
        </td>
    </tr>
    <tr v-if="localAccessTable.scenario" class="tr-darkgray">
        <td colspan="9"> 
            <TableDataScenario :data="data"  />                       
        </td>
    </tr>
    <tr v-if="localAccessTable.deterministic" class="tr-darkgray">
        <td colspan="9"> 
            <TableDataDeterministic :data="data"  />                       
        </td>
    </tr>
    <tr v-if="localAccessTable.probabilistic" class="tr-darkgray">
        <td colspan="9"> 
            <TableDataProbabilistic :data="data"  />                       
        </td>
    </tr>
    <tr v-if="localAccessTable.sensitivity" class="tr-darkgray">
        <td colspan="9"> 
            <TableDataSensitivity :data="data"  />                       
        </td>
    </tr>
    <tr v-if="localAccessGraph.base_case" class="tr-darkgray">
        <td colspan="9"> 
            <TableChartBaseCase :data="data" />                       
        </td>
    </tr>
    <tr v-if="localAccessGraph.scenario" class="tr-darkgray">
        <td colspan="9"> 
            <TableChartScenario :data="data" />                       
        </td>
    </tr>

    <tr v-if="localAccessResult" class="tr-darkgray">
        <td colspan="9"> 
            <div class="bg-white box-table-sub">
                <table class="table table-bordered">
                    <caption>Table results not available due to confidentiality </caption>
                    <tbody>
                        <tr>                           
                            <td>Results not available due to confidentiality  
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="button-fixed"><button type="button" class="btn">Results</button></div>
            </div>  
        </td>
    </tr>

    
</template>

<script>

import { computed, reactive, watch, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
// import { DDMMMYY } from '@/utils'
import TableDataBaseCase from '@/components/result/TableDataBaseCase.vue'
import TableDataDeterministic from '@/components/result/TableDataDeterministic.vue'
import TableDataProbabilistic from '@/components/result/TableDataProbabilistic.vue'
import TableDataScenario from '@/components/result/TableDataScenario.vue'
import TableDataSensitivity from '@/components/result/TableDataSensitivity.vue'
import TableChartBaseCase from '@/components/result/TableChartBaseCase.vue'
import TableChartScenario from '@/components/result/TableChartScenario.vue'
 
    
export default {
    components:{
        TableDataBaseCase,
        TableDataDeterministic,
        TableDataProbabilistic,
        TableDataScenario,
        TableDataSensitivity,
        TableChartBaseCase,
        TableChartScenario
    },    
    props:{
        data: {
            type: Object,
            required: true
        },
    },
    setup(props) {
        
        
        const localAccessTable = reactive({
            'base_case' : false,
            'scenario' : false,
            'deterministic' : false,
            'probabilistic' : false,
            'sensitivity' : false,
        });

        const localAccessGraph = reactive({
            'base_case' : false,
            'scenario' : false
        });

        const localAccessResult = ref(false)

        /**
         * Check if there is any information.
         * @return {Object}
         */
        const getDefaultitem = () => {
            return {
                'base_case' : false,
                'scenario' : false,
                'deterministic' : false,
                'probabilistic' : false,
                'sensitivity' : false,
                
                'chart_base_case' : false,
                'chart_scenario' : false,
                'private' : false        
            }
        }
        const hasData = computed(() => {  
            // let temp = {
            //     'base_case' : false,
            //     'scenario' : false,
            //     'deterministic' : false,
            //     'probabilistic' : false,
            //     'sensitivity' : false,
                
            //     'chart_base_case' : false,
            //     'chart_scenario' : false,
            //     'private' : false,
            // }   
            let temp = {...getDefaultitem()}
               
            if(props.data.economic_base_case.length){
                temp.base_case = true
                props.data.economic_base_case.forEach((item) => {
                    if(item.line_subgroup || item.comparator ){
                        temp.chart_base_case = true 
                    }
                });
            }   
            if(props.data.economic_analysis){
                props.data.economic_analysis.forEach((item) => {
                    if(item.economic_analysis_result.length){
                        item.economic_analysis_result.forEach((item2) => { 
                            if(item2.private){
                                temp.private = true
                            }                           
                            if(item2.scenario && item2.scenario.length){
                                item2.scenario.forEach((item3) => {   
                                    if((item3.parameter || item3.comment) && (item3.icer || item3.icur || item3.exceed || item3.less || item3.na || item3.dominated || item3.reference) ){
                                        temp.chart_scenario = true  
                                    }
                                });                                
                                temp.scenario = true                                
                            }
                            if(item2.deterministic && item2.deterministic.length){
                                temp.deterministic = true                                
                            }
                            if(item2.probabilistic && item2.probabilistic.length){
                                temp.probabilistic = true                                
                            }
                            if(item2.sensitivity && item2.sensitivity.length){
                                temp.sensitivity = true                                
                            }
                        })                        
                    } 
                })
            } 
            if(temp.private){
                temp = {...getDefaultitem(), 'private' : true}
            }         
            return temp
        })
        
        const store = useStore()
        /**
         * get global access from vuex
         * @return {Object}
         */
        const globalAccessTable = computed(() => store.state.view_selection.table_data );  
        
        /**
         * set show, hide item box in "Table Data"
         */
        const watch_globalAccessTable = () => {
            localAccessTable.base_case = hasData.value.base_case && globalAccessTable.value.includes('base_case') ? true : false
            localAccessTable.scenario = hasData.value.scenario && globalAccessTable.value.includes('scenario') ? true : false
            localAccessTable.deterministic = hasData.value.deterministic && globalAccessTable.value.includes('deterministic') ? true : false
            localAccessTable.probabilistic = hasData.value.probabilistic && globalAccessTable.value.includes('probabilistic') ? true : false
        }
        watch(globalAccessTable, ()  => { 
            watch_globalAccessTable()     
        })         
        onMounted(() => {
            watch_globalAccessTable()
        })

        const globalAccessGraph = computed(() => store.state.view_selection.graph_data );  
        
        /**
         * set show, hide item box in "Graph Data"
         */
        const watch_globalAccessGraph = () => {
            localAccessGraph.base_case = hasData.value.chart_base_case && globalAccessGraph.value.includes('base_case') ? true : false
            localAccessGraph.scenario = hasData.value.chart_scenario && globalAccessGraph.value.includes('scenario') ? true : false
        }
        watch(globalAccessGraph, ()  => { 
            watch_globalAccessGraph()     
        })         
        onMounted(() => {
            watch_globalAccessGraph()
        })

        return {localAccessTable, localAccessGraph, localAccessResult, hasData}
    }
}
</script>