<template>  
    <div class="footer">
        <div class="footer-info" @click="BackToTop" >
            <font-awesome-icon icon="chevron-up" :style="{ fontSize: '18px', marginBottom:'4px' }" />
            <div>Back to top</div>
        </div>
    </div>
    <ModalAlertBox />
</template>

<script>
import ModalAlertBox from '@/components/elements/ModalAlertBox.vue' 
export default {
    components : {
        ModalAlertBox
    },
    setup(){
        const BackToTop = () => {
            window.scrollTo(0,0);
        }
        return { BackToTop }
    }
}
</script>