<template>
    <div class="bg-white box-table-sub">
                                                 
        <table class="table table-bordered">
            <caption>Key documents</caption>
            <tbody>
                <tr v-for="(item, key) in data.key_documents" :key="key">
                    <td style="width: 50%;">{{ checkEmpty(item.title) }}</td>
                    <td style="width: 25%;" class="text-center">{{ item.date ? DDMMMYY(item.date) : "-"}}</td>
                    <td style="width: 25%;" class="text-center">
                        <a v-if="!item.pdf" :href="'/share/uploads/EconomicEvaluationKeydoc/'+item.id+'/pdf.pdf'" class="pdf" target="_blank" rel="noopener">
                        <font-awesome-icon :icon="['far', 'file-pdf']"  :style="{ fontSize: '16px', color : '#000' }"/>
                        </a>
                        <a v-else :href="item.link" class="pdf" target="_blank" rel="noopener">
                            <font-awesome-icon :icon="['fas', 'globe-americas']"  :style="{ fontSize: '16px', color : '#000' }"/>
                        </a>
                    </td>
                </tr>                                                  
            </tbody>
        </table>
        
        <div class="button-fixed">
            <button type="button" class="btn">Key documents</button>
        </div>
    </div>  
</template>

<script>

import { DDMMMYY, checkEmpty } from '@/utils'
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    setup() {
        
        return {DDMMMYY, checkEmpty}
    }
}
</script>