<template>
    <div class="box-bg-gray box-radius-1 main-overlay-np">
        <div class="row row-ct px-1">
            <div class="col-12 ">
                <div class="bg-white main-graph">
                <h3>Type of economic analysis</h3>

                <div class="box-graph">
                    <highcharts v-if="chartOptions.series[0].data.length" :options="chartOptions" ></highcharts>
                    <div v-else-if="!loadChart" class="graph-nodata"> NO DATA</div>
                </div>
                </div>
            </div>
        </div>
        <div v-if="loadChart" class="overlay">
            <div class="loadding_circle"></div>
        </div>
    </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import { computed, reactive, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
//stockInit(Highcharts)
export default {
    components : {
        highcharts: Chart 
    },
    emits: ['request_data'],
    setup(props, { emit }) {

        const chartOptions = reactive({
            chart: {
                type: 'bar'
            },
            title: {
                text: null
            },
            xAxis: {
                type: 'category',
            },
            yAxis: {
                title: {
                    text: null
                }
            },
            exporting: { enabled: false },
            legend: { enabled: false },
            credits: { enabled: false  },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                    }
                }
            },

            tooltip: {
                headerFormat : null,
                // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
            },

            series: [
                {
                    name: "Type of economic analysis",
                    color: '#3c98ce',
                    //colorByPoint: true,
                    point: {
                        events: {
                            click: function() {
                                console.log(this.type_of_economic_analysis+"--"+this.y);
                                emit('request_data', {filter_type_of_economic_analysis : this.type_of_economic_analysis})
                            }
                        }
                        },
                    data: []
                }
            ]
        });



        const { t } = useI18n()
        const store = useStore()
        /**
         *  get data from vuex
         */
        const data = computed(() => store.getters['result/getDataChartByType']("type_economic"))
        const loadChart = computed(() => store.state.result.load_chart_normal);  
        /**
         *  set series to chartOptions
         */
        const setDataToChart = () => {
            if(data.value && data.value.length > 0){       
                const temp = [];
                data.value.forEach((val) => {
                    temp.push({ ...val, name : t(val.name), type_of_economic_analysis : val.name})
                });
                chartOptions.series[0].data = temp            
            }
        }
        watch(data, ()  => {
            setDataToChart()     
        })         
        onMounted(() => {
            setDataToChart()
        })
        
        return {chartOptions, loadChart}
    }

}
</script>