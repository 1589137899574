<template>
    
    <div class="bg-white box-table-data-sub essais-clinique main-overlay" >
        <div v-if="load" class="overlay">
            <div class="loadding_circle" style="width: 25px; height: 25px;top: 7px;margin:0"></div>
        </div>
        <template v-if="data">
            <div v-for="(item, key) in data" :key="key">
                <table class="table table-bordered">
                    <caption>Table EssaisCliniques</caption>
                    <tbody>
                        <tr>
                            <td style="width: 30%">
                                Trial name<br>
                                <div v-if="item['trial_name'] || item['nct_id']" class="cursor-pointer" data-bs-toggle="collapse" :data-bs-target="`#detail_${pk_id}_${key}`" aria-expanded="false">
                                    <div><strong class="text-blue">{{item['trial_name']}}</strong></div>                                    
                                    <strong> {{checkEmpty(item['nct_id'])}} </strong>
                                </div>
                            </td>
                            <td class="text-left">
                                <div v-for="(item_official_title, key_official_title) in item['official_title'].split(',')" v-html="item_official_title" :key="key_official_title"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="collapse" :id="`detail_${pk_id}_${key}`">
                    <table class="table table-bordered" >   
                        <caption>Table EssaisCliniques detail</caption>                     
                        <thead>
                            <tr>
                                <th scope="col" style="width: 14%">Study phase</th>
                                <th scope="col" style="width: 14%">Trial details</th>
                                <th scope="col" style="width: 10%">Number of patients</th>
                                <th scope="col" style="width: 10%">Status</th>
                                <th scope="col" style="width: 14%">Intervention</th>
                                <th scope="col" style="width: 10%">Type</th>
                                <th scope="col" >Comparaison</th>
                            </tr> 
                        </thead>
                        <tbody>
                            <tr>            
                                <td>{{checkEmpty(item['phase'])}}</td>
                                <td>
                                    <div>{{checkEmpty(item['allocation'])}}</div>
                                    <div>{{checkEmpty(item['masking'])}}</div>
                                </td> 
                                <td>{{checkEmpty(item['enrollment'])}}</td>
                                <td>{{checkEmpty(item['overall_status'])}}</td>
                                <td>
                                    <template v-for="(val, kk) in item.intervention" :key="'inventions_'+kk">
                                        <div v-html="val['name']"></div>
                                    </template>
                                </td>
                                <td>{{checkEmpty(item['ec_type'])}}</td>
                                <td>{{checkEmpty(item['ec_comparateur'])}}</td>
                            </tr>
                        </tbody>
                    </table>

                    <template v-if="item.outcome.length > 0">
                        <template v-for="(item_outcome, key_outcome) in item.outcome" :key="'key_outcome_'+key_outcome">
                            <table class="table table-bordered" >
                                <caption>Table Reservations Endpoint</caption>
                                <thead>
                                    <tr>            
                                        <th scope="col" style="width: 75%;" class="text-left text-blue">
                                            <div class="cursor-pointer" data-bs-toggle="collapse" :data-bs-target="`#detail_${pk_id}_${key}_${key_outcome}`" aria-expanded="false">                                                 
                                                {{checkEmpty(item_outcome['outcome_type'])}} Endpoint 
                                                <span class="span-chevron-down"><font-awesome-icon icon="chevron-down" :style="{fontSize : '10px', padding : 0}" /></span>
                                                <span class="span-chevron-up"><font-awesome-icon icon="chevron-up" :style="{fontSize : '10px', padding : 0}" /></span>
                                            </div> 
                                        </th>
                                        <th scope="col" style="width: 25%">Units</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>            
                                        <td class="text-left">{{checkEmpty(item_outcome['title'])}}</td>
                                        <td>{{checkEmpty(item_outcome['units'])}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="collapse" :id="`detail_${pk_id}_${key}_${key_outcome}`" >
                                
                                <table class="table table-bordered">
                                    <caption>Table Reservations outcome_measurement</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col" style="width: 25%">Arm</th>
                                            <th scope="col" style="width: 25%">Results</th>
                                            <th scope="col" style="width: 25%">CI 95 % min</th>
                                            <th scope="col" style="width: 25%">CI 95% max</th>
                                        </tr>                                                            
                                    </thead>  
                                    <tbody>
                                        <template v-for="(item_measure, key_measure) in item_outcome['outcome_measurement']" :key="'key_measure_tr_1_'+key_measure">
                                            <tr>  
                                                <td colspan="4" style="font-weight: bold; text-align: left" v-if="item_measure['category'] || item_measure['classification']"> 
                                                    {{item_measure['category']}} {{item_measure['classification']}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{checkEmpty(item_measure['arm'])}}</td>
                                                <td>{{checkEmpty(item_measure['param_value'])}}</td>
                                                <td>{{checkEmpty(item_measure['dispersion_lower_limit'])}}</td>
                                                <td>{{checkEmpty(item_measure['dispersion_upper_limit'])}}</td>
                                            </tr> 
                                        </template>
                                    </tbody>   
                                </table>          
                              
                                <table class="table table-bordered" v-if="item_outcome['outcome_analyse'].length > 0">
                                    <caption>Table Reservations outcome_analyse</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col" style="width: 35%">Group</th>
                                            <th scope="col" style="width: 11%">RR/HR/OR Type</th>
                                            <th scope="col" style="width: 8%">RR/HR/OR</th>
                                            <th scope="col" style="width: 8%">CI 95 % min</th>
                                            <th scope="col" style="width: 8%">CI min</th>
                                            <th scope="col" style="width: 8%">CI max</th>
                                            <th scope="col" style="width: 8%">P Value</th>
                                        </tr>                                                            
                                    </thead>  
                                    <tbody>
                                        <tr v-for="(item_analyse, key_analyse) in item_outcome['outcome_analyse']" :key="'key_analyse_'+key_analyse">
                                            <td>{{checkEmpty(item_analyse['groups'])}}</td>
                                            <td>{{checkEmpty(item_analyse['param_type'])}}</td>
                                            <td>{{checkEmpty(item_analyse['param_value'])}}</td>
                                            <td>
                                                <template v-if="item_analyse['ci_lower_limit'] && item_analyse['ci_upper_limit']">{{item_analyse['ci_lower_limit']}} to {{item_analyse['ci_upper_limit']}}</template>
                                                <template v-else> - </template>
                                            </td>
                                            <td>{{checkEmpty(item_analyse['ci_lower_limit'])}}</td>
                                            <td>{{checkEmpty(item_analyse['ci_upper_limit'])}}</td>
                                            <td>{{checkEmpty(item_analyse['p_value'])}}</td>
                                        </tr> 
                                    </tbody>   
                                </table>
                            </div>
                        </template>
                    </template>

                    <template v-else-if="item['design_outcome'].length > 0">
                        <table class="table table-bordered" v-for="(item_design_outcome, key_design_outcome) in item['design_outcome']" :key="'key_design_outcome_'+key_design_outcome" 
                        >
                            <caption>Table Reservations design_outcome</caption>
                            <thead>
                                <tr>            
                                    <th scope="col" style="width: 75%;" class="text-left text-blue">                                        
                                        {{checkEmpty(capitalize(item_design_outcome['outcome_type']))}} Endpoint
                                    </th>
                                    <th scope="col" style="width: 25%">Units</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>            
                                    <td class="text-left">{{checkEmpty(item_design_outcome['title']) }}</td>
                                    <td> - </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>


                </div>
            </div>
        </template>
    </div>
</template>

<script>

import { computed, onMounted, ref } from 'vue' 
import { useStore } from 'vuex' 
import { checkEmpty } from '@/utils'
export default {
    props: {
        id: {
            type : Number,
            required: true
        },
        pk_id: {
            type : Number,
            required: true
        },
    },
    setup(props) {
        const store = useStore()
        const load = ref(false)
        /**
         *  get data from vuex
         */
        const data = computed(() => store.getters['result/getEssaisCliniqueById'](props.id))
        /**
         * request service api/essais_clinique/{id}
        */ 
        onMounted(() => {
            if(!data.value){                 
                load.value = true
                store.dispatch('result/essais_clinique', props.id).then(
                    () => {
                        load.value = false
                    }
                )
            }            
        })
        /**
         * capitalize
         * @property {String} str = outcome_type
         * @return {String}
         */
        const capitalize = (str) => {
            return str[0].toUpperCase() + str.slice(1)
        }
         
        
        return {data, load, checkEmpty, capitalize}
    }
}
</script>