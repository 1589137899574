<template>
    <tr class="tr-darkgray">
        <td colspan="9"> 
            
            <div class="box-switch">
                <div class="form-check form-switch from-check-ct" v-if="0">
                    <input class="form-check-input" type="checkbox" v-model="controlBox['timeline']" :id="`checkBoxTimeline${data.pk_id}`">
                    <label class="form-check-label" :for="`checkBoxTimeline${data.pk_id}`">Timeline</label>
                </div> 
                <div class="form-check form-switch from-check-ct" v-if="check_introduction">
                    <input class="form-check-input" type="checkbox" v-model="controlBox['introduction']" :id="`checkBoxIntroduction${data.pk_id}`">
                    <label class="form-check-label" :for="`checkBoxIntroduction${data.pk_id}`">Introduction</label>
                </div> 
                <div class="form-check form-switch from-check-ct" v-if="data.key_documents">
                    <input class="form-check-input" type="checkbox" v-model="controlBox['key_document']" :id="`checkBoxKeyDocument${data.pk_id}`">
                    <label class="form-check-label" :for="`checkBoxKeyDocument${data.pk_id}`">Key documents</label>
                </div> 
                <div class="form-check form-switch from-check-ct" v-if="check_discussion">
                    <input class="form-check-input" type="checkbox" v-model="controlBox['discussion']" :id="`checkBoxDiscussion${data.pk_id}`">
                    <label class="form-check-label" :for="`checkBoxDiscussion${data.pk_id}`">Discussion</label>
                </div> 
                <div class="form-check form-switch from-check-ct" v-if="check_limitations">
                    <input class="form-check-input" type="checkbox" v-model="controlBox['limitation']" :id="`checkBoxLimitation${data.pk_id}`">
                    <label class="form-check-label" :for="`checkBoxLimitation${data.pk_id}`">Limitations</label>
                </div> 
                <div class="form-check form-switch from-check-ct" v-if="check_method" >
                    <input class="form-check-input" type="checkbox" v-model="controlBox['method']" :id="`checkBoxMethod${data.pk_id}`">
                    <label class="form-check-label" :for="`checkBoxMethod${data.pk_id}`">Method</label>
                </div>
                <div class="form-check form-switch from-check-ct" v-if="check_reservations" >
                    <input class="form-check-input" type="checkbox" v-model="controlBox['reservations']" :id="`checkBoxReservation${data.pk_id}`">
                    <label class="form-check-label" :for="`checkBoxReservation${data.pk_id}`">Reservations</label>
                </div>

            </div>                      
        </td>
    </tr>
    <tr v-if="controlBox.introduction" class="tr-darkgray">
        <td colspan="9"> 
            <Introduction :data="data" />                       
        </td>
    </tr>
    <tr v-if="controlBox.key_document" class="tr-darkgray">
        <td colspan="9"> 
            <KeyDocument :data="data" />                       
        </td>
    </tr>
    <tr v-if="controlBox.discussion" class="tr-darkgray">
        <td colspan="9"> 
            <Discussion :data="data" />                       
        </td>
    </tr>
    <tr v-if="controlBox.limitation" class="tr-darkgray">
        <td colspan="9"> 
            <Limitation :data="data" />                       
        </td>
    </tr>    
    <tr v-if="controlBox.method" class="tr-darkgray">
        <td colspan="9"> 
            <Method :data="data" />                       
        </td>
    </tr>
    <tr v-if="controlBox.reservations" class="tr-darkgray">
        <td colspan="9"> 
            <CeespReservation :data="data" />                       
        </td>
    </tr>
    
</template>


<script>
import { computed, reactive, watch, onMounted } from 'vue'
import { DDMMMYY } from '@/utils'
import { useStore } from 'vuex'
import Introduction from '@/components/result/Introduction.vue'
import Method from '@/components/result/Method.vue'
import Discussion from '@/components/result/Discussion.vue'
import Limitation from '@/components/result/Limitation.vue'
import KeyDocument from '@/components/result/KeyDocument.vue'
import CeespReservation from '@/components/result/CeespReservation.vue'
export default {
    components:{
        Introduction,
        Method,
        Discussion,
        Limitation,
        KeyDocument,
        CeespReservation
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    setup(props) {

        const controlBox = reactive({
            'timeline' : false,
            'introduction' : false,
            'key_document' : false,
            'discussion' : false,
            'limitation' : false,
            'method' : false,
            'reservations' : false
        });

        /**
         * Check if there is any information of introduction.
         * @return {boolean}
         */
        const check_introduction = computed(() => {
            return props.data.decision || props.data.guide_date || props.data.title || props.data.goal_intervention || props.data.type_of_request || 
            props.data.type_of_economic_analysis || props.data.health_outcome
        })


        const store = useStore()
        /**
         * get global access from vuex
         * @return {Object}
         */
        const globalAccessLimitation = computed(() => store.state.view_selection.limitation); 
        /**
         * Check if there is any information of limitations.
         * @return {boolean}
         */       
        const check_limitations = computed(() => {  
            let temp = false  
            if(props.data.economic_analysis){
                props.data.economic_analysis.forEach((item) => {
                    if(globalAccessLimitation.value.length){
                        if((item.limitations_population && globalAccessLimitation.value.includes('population')) ||
                            (item.limitations_measurement_of_long_term_outcome && globalAccessLimitation.value.includes('extrapolation')) ||
                            (item.limitations_measurement_of_utility && globalAccessLimitation.value.includes('measurement_utility')) ||
                            (item.limitations_measurement_of_cost && globalAccessLimitation.value.includes('measurement_cost')) ||
                            (item.limitations_uncertainty_of_clinical_parameters && globalAccessLimitation.value.includes('clinical_evidence')) ||
                            (item.limitations_uncertainty_of_cost_effectiveness_outcomes && globalAccessLimitation.value.includes('uncertainty_cost_effectiveness_outcomes')) ||
                            (item.limitations_model_design && globalAccessLimitation.value.includes('model_design')) ||
                            (item.limitations_comparators && globalAccessLimitation.value.includes('comparators')) ||
                            (item.limitations_assumptions && globalAccessLimitation.value.includes('assumptions')) ||
                            (item.limitations_price && globalAccessLimitation.value.includes('price'))){
                            temp = true;
                        }
                    }else if(item.limitations_population || item.limitations_measurement_of_long_term_outcome || item.limitations_measurement_of_utility
                            || item.limitations_measurement_of_cost || item.limitations_uncertainty_of_clinical_parameters
                            || item.limitations_uncertainty_of_cost_effectiveness_outcomes || item.limitations_model_design || item.limitations_comparators
                            || item.limitations_assumptions || item.limitations_price){
                            temp = true;                        
                    }
                })
            }          
            return temp
        })
        /**
         * set show, hide box "Limitations"
         */
        const watch_globalAccessLimitation = () => {
            if(!globalAccessLimitation.value.length || !check_limitations.value){ 
                controlBox.limitation = false
            }else if(check_limitations.value){
                controlBox.limitation = true
            } 
        }
        watch(globalAccessLimitation, ()  => { 
            watch_globalAccessLimitation()     
        })         
        onMounted(() => {
            watch_globalAccessLimitation()
        })

        /**
         * get global access from vuex
         * @return {Object}
         */
        const globalAccessMethod = computed(() => store.state.view_selection.method); 
        /**
         * Check if there is any information of method.
         * @return {boolean}
         */
        const check_method = computed(() => {  
            let temp = false  
            if(props.data.economic_analysis){
                props.data.economic_analysis.forEach((item) => {
                    if(globalAccessMethod.value.length){
                        if((globalAccessMethod.value.includes('analytic_framework')) ||
                            (item.structure_model && globalAccessMethod.value.includes('structure_model')) ||
                            ((item.review_of_literature_for_comparators || item.meta_analysis_method_for_comparators || item.comment
                                || item.review_of_literature_for_comparators || item.nct_numbers) && globalAccessMethod.value.includes('measurement')) ||
                            (item.extrapolation && globalAccessMethod.value.includes('extrapolation')) ||
                            (item.utility_value && globalAccessMethod.value.includes('utility_values')) ||
                            ((item.resources || item.resources_sources || item.type_of_costs
                                || item.type_of_costs_sources || item.currency_and_price_date_date || item.currency_and_price_date_currency) && globalAccessMethod.value.includes('resources_costs')) ||
                            ((item.structural_sensibility_analysis || item.deterministic_sensibility_analysis || item.probabilistic_sensibility_analysis
                                || item.scenario_analysis) && globalAccessMethod.value.includes('sensitivity_analysis')) 
                            ){
                            temp = true;
                        }
                    }else{
                        temp = true;
                    }  

                })
            }          
            return temp
        }) 
        /**
         * set show, hide box "Method"
         */
        const watch_globalAccessMethod = () => {
            if(!globalAccessMethod.value.length || !check_method.value){ 
                controlBox.method = false
            }else if(check_method.value){
                controlBox.method = true
            }  
        }
        watch(globalAccessMethod, ()  => { 
            watch_globalAccessMethod()     
        })         
        onMounted(() => {
            watch_globalAccessMethod()
        })

        /**
         * get global access from vuex
         * @return {Object}
         */
        const globalAccessDiscussion = computed(() => store.state.view_selection.discussion);  
        /**
         * Check if there is any information of discussion.
         * @return {boolean}
         */               
        const check_discussion = computed(() => {  
            let temp = false          
            if(props.data.economic_analysis){
                props.data.economic_analysis.forEach((item) => {
                    if(globalAccessDiscussion.value.length){
                        
                        
                        if( (item.discussion_study_finding && globalAccessDiscussion.value.includes('study_finding')) ||
                            (item.discussion_assumptions && globalAccessDiscussion.value.includes('assumptions')) ||
                            (item.discussion_comparision_with_other_studies && globalAccessDiscussion.value.includes('comparison_studies')) ||
                            (item.discussion_validation && globalAccessDiscussion.value.includes('validation')) ||
                            (item.discussion_generalizability_transferability && globalAccessDiscussion.value.includes('transferability')) ||
                            (item.discussion_extrapolation && globalAccessDiscussion.value.includes('extrapolation')) ||
                            (item.discussion_conclusion && globalAccessDiscussion.value.includes('conclusion')) ||
                            (item.discussion_comment && globalAccessDiscussion.value.includes('comment'))){
                            temp = true;
                        }
                    }else if(item.discussion_study_finding || item.discussion_assumptions || item.discussion_comparision_with_other_studies
                        || item.discussion_validation || item.discussion_generalizability_transferability || item.discussion_extrapolation
                        || item.discussion_conclusion || item.discussion_comment){
                        temp = true;
                    }
                })
            } 
            return temp
        })
        /**
         * set show, hide box "Discussion"
         */
        const watch_globalAccessDiscussion = () => {
            if(!globalAccessDiscussion.value.length || !check_discussion.value){ 
                controlBox.discussion = false
            }else if(check_discussion.value){
                controlBox.discussion = true
            }  
        }
        watch(globalAccessDiscussion, ()  => { 
            watch_globalAccessDiscussion()     
        })         
        onMounted(() => {
            watch_globalAccessDiscussion()
        })
        

        /**
         * Check if there is any information of ceesp reserves.
         * @return {boolean}
         */
        const check_reservations = computed(() => {
            return props.data.agency_model === "Ceesp" && props.data.ceesp_reserves  
        })

        return {DDMMMYY, check_introduction, check_discussion, check_limitations, check_reservations, controlBox,
        check_method, globalAccessDiscussion}
    }
}
</script>