<template>
    <div class="main-overlay-np">
        <div v-if="!dateRange[0]" class="box-bg-gray box-radius-1">
            <div class="col-12 ">
                <div class="bg-white main-graph"> 
                    <h3>ICER landscape (base case)</h3>
                    <div class="box-graph">
                        <div v-if="!loadChart" class="graph-nodata"> NO DATA</div>
                    </div>
                </div>
            </div>         
        </div>
        <div v-else class="box-bg-gray box-radius-1">
            <div class="box-ui-tab mb-1 px-1">
                <div class="row row-ct">
                    
                    <div class="col-12 col-md-4 pe-0">
                        <div class="d-flex">
                            <div style="width: 215px;"> ICER landscape (base case)</div>
                            <div style="width: 50%;">
                                <select name="filter_therapeutic_area" v-model="filter_therapeutic_area" class="select-option btn btn-sm btn-sea-blue">                                
                                    <option v-for="(item, key) in list_therapeutic_area" :key="key">
                                        {{item}}
                                    </option>
                                </select>                           
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 pe-0">
                        <div class="box-bg-lightgray">
                            <div class="d-flex">
                                <div class="me-3"><strong>Date</strong></div>
                                <div style="width: 85%;">
                                    <div class="box-wrapper-lines">
                                        <div class="subline" style="width: 18%;">{{ DDMMMYY(new Date(filterDateRange[0]*1000)) }}</div>
                                        <div style="width: 60%;padding: 0 5px;" class="subline">
                                            <Slider v-model="filterDateRange" :tooltips="false" :min="dateRange[0]" :max="dateRange[1]" :step="86400" class="slider-blue"/>
                                        </div>
                                        <div class="subline">{{DDMMMYY(new Date(filterDateRange[1]*1000))}}</div>
                                    </div>                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="box-bg-lightgray">
                            <div class="d-flex w-100">
                                <div class="me-3"><strong>ICER range</strong></div>
                                <div style="width: 75%;">
                                    <div class="box-wrapper-lines">
                                        <div style="width: 15%;" class="subline">{{filterIcerRange[0]}}</div>
                                        <div style="width: 60%;padding: 0 5px;" class="subline">
                                            <Slider v-model="filterIcerRange" :tooltips="false" :min="IcerRange[0]" :max="IcerRange[1]" :step="1" class="slider-blue"/>
                                        </div>
                                        <div class="subline">{{filterIcerRange[1]}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row row-ct px-1">
                <div class="col-12 col-md-9 pe-0">                   
                    <div class="bg-white main-graph">
                    
                        <div class="box-graph">
                            <highcharts v-if="chartOptions.xAxis.categories.length" :options="chartOptions" ></highcharts>
                            <div v-else-if="!loadChart" class="graph-nodata"> NO DATA</div>
                        </div>
                    </div>

                    <div class="box-list-legend">
                        <ul>
                            <li><span><img src="@/assets/images/icon-arrow-up.png" alt="" > Average ICER per country</span></li>
                            <li><span><img src="@/assets/images/icon-square-blue.png" alt="" > Total ICER range</span></li>
                        </ul>
                    </div>
                    <div class="my-3 mx-1">*Click on flag for more information</div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="mb-2"><strong>Treatment Line</strong></div>
                    <div class="wrap-box-line">
                        <label v-for="(item, key) in list_treatment_line" :key="key" class="list-bg-white box-bg-lightgray">
                            <div>{{item.label}}</div>
                            <div><input class="form-check-input" type="checkbox" :value="item.value" v-model="filter_treatment_line"></div>
                        </label>
                        
                        <div class="mb-2"><strong>Drugs</strong></div>
                        <label v-for="(item, key) in list_name" :key="key"  class="list-bg-white box-bg-lightgray">
                            <div>{{item}}</div>
                            <div><input class="form-check-input" type="checkbox" :value="item" v-model="filter_name"></div>
                        </label>
                    </div>
                    <div class="button-fixed">
                        <button class="btn btn-sea-blue btn-form" @click="handleFilterBasecase">Filter</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loadChart" class="overlay">
            <div class="loadding_circle"></div>
        </div>
    </div>
    <ChartBaseCaseCountry v-if="show_base_case_country_chart" :filter_parent="dataFilterForChartCountry" @request_data="emit_request_data" @request_result_chart_base_case_country="emit_request_result_chart_base_case_country"/>
    <ModalBaseCase idModal="modal-basecase" />
</template>

<script>
import { computed, reactive, onMounted, onUnmounted, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { DDMMMYY } from '@/utils'
import moment from 'moment'
import { Chart } from "highcharts-vue";
import More from 'highcharts/highcharts-more';
import HighchartsCustomEvents from "highcharts-custom-events";
import Highcharts from 'highcharts'
More(Highcharts)
HighchartsCustomEvents(Highcharts);
import { Modal } from 'bootstrap'
import ModalBaseCase from '@/components/result/ModalBaseCase.vue'
import ChartBaseCaseCountry from '@/components/result/ChartBaseCaseCountry.vue'


import Slider from '@vueform/slider'
import '@vueform/slider/themes/default.css'
import "@/assets/css/range.css";
export default {
    components : {
        highcharts: Chart,
        ModalBaseCase,
        ChartBaseCaseCountry,
        Slider
    },
    emits: ['request_data', 'request_result_chart_base_case', 'request_result_chart_base_case_country'],
     
    setup(props, { emit }) {
        
        
        const chartOptions = reactive({
               
            credits: { enabled: false },
            exporting: { enabled: false },
            title: { text: null },
            subtitle: { text: null },
            legend: { enabled: false },
            xAxis: {
                title: {
                    enabled: true,
                    text: null
                },
                categories: [], //put Data
                gridLineWidth: 1,
                lineColor: '#ddd',
                gridLineColor: '#ddd',
                tickWidth: 0,                
                labels: {
                    color: '#fff',                  
                    x: 0,
                    useHTML: true,                    
                    events: {                    
                        click: function () {
                            const agency_model = this.value.match(/<div class="hide">(.*?)<\/div>/i)[1];                            
                            showBasecaseCountry(agency_model);
                        }
                    },
                    formatter: function () { 
                        return '<div class="text-center cursor-pointer" ><div>' + this.value  +'</div><div class="flag size20 '+data_chart.value.icon_flags[this.value]+'"></div><div class="hide">'+data_chart.value.agency_model[this.value]+'</div></div>';
                    },                    
                },
                plotLines: [] //put data On load
            },
            yAxis: {
                min:0,
                title: { text: null },
                plotLines: [], //put data On load     
                minorTickInterval: 'auto',
            },
            tooltip: {  
                enabled: false,
            },
                    
            plotOptions: {                
                scatter: {
                    marker: {
                    symbol:'circle',
                    radius: 5,
                    states: {
                        hover: {
                        enabled: true,
                        lineColor: 'rgb(100,100,100)'
                        }
                    }
                    }
                },
                series: { 
                    point: {
                    events: {
                        click: function () { 
                            openModal(this.category);
                        }
                    },
                    }
                },
                columnrange: {
                    pointWidth: 11                    
                },
            },
            series: [
            {
                name: 'Range',
                type: 'columnrange',
                color: '#0C9CDC',
                lineColor: '#0C9CDC',
                data: [], //put data
            },
            {
                name: 'Exceed', //>
                type: 'scatter',
                lineColor: 'transparent',
                showInLegend: false, 
                enableMouseTracking: false,
                data: [], //put data
                marker: {
                radius: 5,
                fillColor: '#0C9CDC',
                symbol: 'url(https://www.prismheor.com/img/prismheor/triangle.png)',
                states: {
                    hover: {
                    enabled: false
                    }
                }
                }
            },
            {
                name: 'Less', //<
                type: 'scatter',
                lineColor: 'transparent',
                showInLegend: false, 
                enableMouseTracking: false,
                data: [], //put data
                marker: {
                radius: 5,
                fillColor: '#0C9CDC',
                symbol: 'url(https://www.prismheor.com/img/prismheor/triangle-down.png)',
                states: {
                    hover: {
                    enabled: false
                    }
                }
                }
            },
            {
                name: 'Single',
                type: 'scatter',
                color: '#0CA0DC',
                lineColor: 'transparent',
                data: [], //put data
                //data: [[0,16311], [5,8200]],
                marker: {
                symbol: 'url(https://www.prismheor.com/img/prismheor/plot-scatter_orange.png)'
                }
            }, 
            {
                name: 'Average',
                type: 'line',
                color: '#ffc000',
                lineColor: 'transparent',
                //enableMouseTracking: false,        
                data: [], //put data
                marker: {
                symbol: 'triangle'
                },
                tooltip: {
                //enabled: false
                },
            },
            ]
        });



        const store = useStore()
        /**
         *  get data chart from vuex
         */
        const data_chart = computed(() => store.state.result.base_case )
        const loadChart = computed(() => store.state.result.load_chart || store.state.result.load_chart_normal);  
        /**
         *  get data table from vuex
         */
        const data_table = computed(() => store.state.result.base_case_table )  
        /**
         *  set series to chartOptions
         */      
        const setDataToChart = () => {
            if(data_chart.value && data_chart.value.categories){                
                chartOptions.xAxis.categories = data_chart.value.categories
                chartOptions.series[0].data = data_chart.value.range
                chartOptions.series[1].data = data_chart.value.exceed
                chartOptions.series[2].data = data_chart.value.less
                chartOptions.series[3].data = data_chart.value.single
                chartOptions.series[4].data = data_chart.value.average
            }
        }
        watch(data_chart, ()  => { 
            setDataToChart()     
        })         
        onMounted(() => {
            setDataToChart()
        })



        /**
         * get ICER range (min, max) from vuex
         */
        const IcerRange = computed(() => store.state.result.base_case_option.icer_range);        
         
        /**
         * ICER range [v-model]
         */
        const filterIcerRange = computed({
            get () { return store.state.result.base_case_filter.icer_range },
            set (value) {                  
                store.commit('result/setFilterIcerRange', value)               
            }
        });       
       
 
        /**
         * get date range (min, max) from vuex
         */
        const dateRange = computed(() => store.state.result.base_case_option.date_range);
        /**
         * Date range min [v-model]
         */
        const filterDateRange = computed({
            get () { return store.state.result.base_case_filter.date_range },
            set (value) {                  
                store.commit('result/setFilterDateRange', value)               
            }
        });


        /**
         * list therapeutic areae from vuex
         */
        const list_therapeutic_area = computed(() => store.state.result.base_case_option.therapeutic_area);   
        /**
         * filter therapeutic area [v-model]
         */     
        const filter_therapeutic_area = computed({
            get () { return store.state.result.base_case_filter.therapeutic_area },
            set (value) { store.commit('result/setFilterTherapeuticArea', value) }
        }); 


        /**
         * get list drug name from vuex
         */
        const list_name = computed(() => store.state.result.base_case_option.name);
        /**
         * filter Drugs [v-model]
         */
        const filter_name = computed({
            get () { return store.state.result.base_case_filter.name },
            set (value) { store.commit('result/setFilterName', value) }
        }); 


        /**
         * list treatment line
         */
        const list_treatment_line = [
            {value : 'First line', label : 'First line'},
            {value : 'Second line', label : 'Second line'},
            {value : 'Third line', label : 'Third line'},
            {value : 'Rescue line', label : 'Rescue line'},
            {value : 'Maintenance', label : 'Maintenance'},
            {value : 'adjuvant', label : 'adjuvant'},
            {value : 'neo-adjuvant', label : 'neo-adjuvant'},
            {value : 'n/a', label : 'N/A'}
        ];
        /**
         * filter Treatment Line [v-model]
         */
        const filter_treatment_line = computed({
            get () { return store.state.result.base_case_filter.treatment_line },
            set (value) { store.commit('result/setFilterTreatmentLine', value) }
        }); 


        //-------------------------- handle click filter chart basecase --------------------------[start]
        const dataFilterForChartCountry = ref({})
        const is_base_case_filter = computed(() => store.state.result.is_base_case_filter);
        /**
         * Onclick filter in ICER landscape (base case) graph
         */
        const handleFilterBasecase = () => {
            
            store.commit('result/setShowBaseCaseCountryChart', false); 
            const objFilter = getFilter();
            dataFilterForChartCountry.value = objFilter;

            if(Object.entries(objFilter).length){
                store.commit('result/setIsBaseCaseFilter', true);
                emit('request_data', objFilter)
                emit('request_result_chart_base_case', objFilter)  //request chart
                emit('request_result_chart_base_case', {...objFilter, 'table' : true}) //request table in modal            
            }else if(is_base_case_filter.value){ //reset filter
                store.commit('result/setIsBaseCaseFilter', false);
                emit('request_data', {})
                emit('request_result_chart_base_case', {}) //request chart
                emit('request_result_chart_base_case', {'table' : true}) //request table in modal
            }
            console.log(objFilter)
        }
        /**
         * get infomation filter in ICER landscape (base case) graph
         */
        const getFilter = () => {
            let objFilter = {};
            const therapeutic_area_all = filter_therapeutic_area.value.match(/^All /g);
            if(!therapeutic_area_all){
                objFilter['filter_therapeutic_area'] = filter_therapeutic_area.value
            }
            if(filter_name.value && filter_name.value.length){
                objFilter['filter_name'] = filter_name.value
            }
            if(filter_treatment_line.value && filter_treatment_line.value.length){
                objFilter['filter_treatment_line'] = filter_treatment_line.value
            }
            if(filterIcerRange.value[0] !== IcerRange.value[0] || filterIcerRange.value[1] !== IcerRange.value[1]){
                objFilter['filter_icer_max'] = filterIcerRange.value[1]
                objFilter['filter_icer_min'] = filterIcerRange.value[0]
            }
            if(filterDateRange.value[0] !== dateRange.value[0] || filterDateRange.value[1] !== dateRange.value[1]){
                objFilter['filter_date_max'] = moment(new Date(filterDateRange.value[1]*1000)).format('YYYY-MM-DD') 
                objFilter['filter_date_min'] = moment(new Date(filterDateRange.value[0]*1000)).format('YYYY-MM-DD') 
            }
            return objFilter;
        }
        //-------------------------- handle click filter chart basecase --------------------------[end]



        //----------------- Modal basecase -------------------[start]
        let modal = null;
        onMounted(() => {
            modal = new Modal(document.getElementById('modal-basecase'));            
        });
        /**
         * Clicking on the graph will show a pop-up.
         * @property {String} acronym 
         */
        const openModal = (acronym) => {
            store.commit('result/setModalBasecase', null);
            const temp = data_table.value.filter(i => i.agency_model == data_chart.value.agency_model[acronym]);
            store.commit('result/setModalBasecase', temp);
            setTimeout(() => modal.show() , 200);            
        }
        //----------------- Modal basecase -------------------[end]


        //--------------------- load chart base base By country ------------------------[start]
        const show_base_case_country_chart = computed(() => store.state.result.show_base_case_country_chart )
        /**
         * Clicking on the graph will show ICER landscape (base case) by country graph
         * @property {String} agency_model 
         */
        const showBasecaseCountry = (agency_model) => {
            const objFilter = getFilter();
            store.commit('result/setShowBaseCaseCountryChart', true);          
            emit('request_result_chart_base_case_country', { filter_agency : agency_model, init : true,  ...objFilter }) 
            emit('request_result_chart_base_case_country', { filter_agency : agency_model, table : true,  ...objFilter }) 
        }
        /**
         * call emit "request_result_chart_base_case_country" of parent component 
         * @property {Object} param 
         */
        const emit_request_result_chart_base_case_country = (param) => {
            emit('request_result_chart_base_case_country', param) 
        }
        /**
         * call emit "request_data" of parent component 
         * @property {Object} param 
         */
        const emit_request_data = (param) => {
            emit('request_data', param) 
        }
        //--------------------- load chart base base By country ------------------------[end]       
        

        onUnmounted(() => {
            store.commit('result/clearStateBaseCaseCountry');
        })

        return {chartOptions, filterIcerRange, IcerRange, dateRange, filterDateRange, DDMMMYY, list_therapeutic_area,
         list_name, filter_therapeutic_area, list_treatment_line, filter_treatment_line, filter_name, handleFilterBasecase,
         show_base_case_country_chart, dataFilterForChartCountry, emit_request_result_chart_base_case_country, 
         emit_request_data, loadChart}
    }

}
</script>
