<template>
    <div class="main-overlay-np">
        <div v-if="list_agency.length == 0" class="box-bg-gray box-radius-1">
            <div class="col-12 ">
                <div class="bg-white main-graph"> 
                    <h3>Limitation landscape</h3>
                    <div class="box-graph">
                        <div v-if="!loadChart" class="graph-nodata"> NO DATA</div>
                    </div>
                </div>
            </div>         
        </div>
        <div v-else class="box-bg-gray box-radius-1">
            <div class="box-ui-tab mb-1 px-1">
                <div class="row row-ct">
                    
                    <div class="col-12 col-md-5 pe-0">
                        <div class="d-flex">
                            <div style="width: 215px;"> Limitation landscape</div>
                            <div style="width: 50%;">
                                <select name="filter_therapeutic_area" v-model="filter_therapeutic_area" class="select-option btn btn-sm btn-sea-blue">                                
                                    <option v-for="(item, key) in list_therapeutic_area" :key="key">
                                        {{item}}
                                    </option>
                                </select>                           
                            </div>
                        </div>
                    </div>                
                </div>

            </div>
            <div class="row row-ct px-1">
                <div class="col-12 col-md-9 pe-0">                
                    <div class="bg-white main-graph">                
                        <div class="box-graph" style="overflow: auto;max-height: 650px;">
                            <highcharts v-if="chartOptions.xAxis[0].categories.length" :options="chartOptions" :style="'height:610px;' + (chartOptions.xAxis[0].categories.length > 60 ? 'width:' + chartOptions.xAxis[0].categories.length*15 + 'px' : '' )"></highcharts>
                            <div v-else class="graph-nodata"> NO DATA</div>
                        </div>
                    </div>
                    <div class="my-3 mx-1">*Click on flag for more information</div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="mb-2"><strong>Agencies</strong></div>
                    <div class="wrap-box-line">
                        <label v-for="(item, key) in list_agency" :key="key" class="list-bg-white box-bg-lightgray">
                            <div>{{item.acronym}}</div>
                            <div><input class="form-check-input" type="checkbox" :value="item.agency_model" v-model="filter_agency"></div>
                        </label>
                        
                        <div class="mb-2"><strong>Drugs</strong></div>
                        <label v-for="(item, key) in list_name" :key="key"  class="list-bg-white box-bg-lightgray">
                            <div>{{item}}</div>
                            <div><input class="form-check-input" type="checkbox" :value="item" v-model="filter_name"></div>
                        </label>

                        <div class="mb-2"><strong>Limitations</strong></div>
                        <label v-for="(item, key) in list_limitation" :key="key"  class="list-bg-white box-bg-lightgray">
                            <div>{{ $t(item) }}</div>
                            <div><input class="form-check-input" type="checkbox" :value="item" v-model="filter_limitation"></div>
                        </label>
                    </div>
                    <div class="button-fixed">
                        <button class="btn btn-sea-blue btn-form" @click="handleFilter">Filter</button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="loadChart" class="overlay">
            <div class="loadding_circle"></div>
        </div>
    </div>
    <ModalLimitationLandscapeCountry :filter_parent="dataFilterForSubChart" :list_limitation="list_limitation" :data="dataSubchart" />
    <ModalLimitation :data="modal_data" :field="modal_key" />
</template>

<script>
import { reactive, computed, watch, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { Chart } from "highcharts-vue";
import Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import HighchartsCustomEvents from "highcharts-custom-events";
import { Modal } from 'bootstrap'
import ModalLimitation from '@/components/result/ModalLimitation.vue'
import ModalLimitationLandscapeCountry from '@/components/result/ModalLimitationLandscapeCountry.vue'

More(Highcharts)
HighchartsCustomEvents(Highcharts);
export default {
     components : {
        highcharts: Chart,
        ModalLimitation,
        ModalLimitationLandscapeCountry
    },
    emits: ['request_data', 'request_result_chart_limitation_landscape'],     
    setup(props, { emit }) {
        
        const { t } = useI18n()

        const chartOptions = reactive({
            credits: { enabled: false },
            exporting: { enabled: false },
            title: { text: null },
            subtitle: { text: null },
            legend: { enabled: false },
            xAxis: [{
                gridLineWidth: 0,
                lineColor: '#9a9194', 
                categories : [],
                labels: {  
                    useHTML: true,
                    step: 1,   
                    events: {                    
                        click: function () {
                           
                            console.log(this.axis.categories[this.pos])
                            
                            showModalLimitationLandscapeCountry(this.axis.categories[this.pos]);                           
                        }
                    },          
                    formatter: function () {
                        const temp = list_agency.value.find(i => i.agency_model == this.value);            
                        return '<div class="text-center" style="padding-top:5px;"><div class="flag size20 '+temp['icon_flags']+'" style="width: 13px;height: 13px;"></div></div>';
                    },
                }
            },
            { 
                opposite: true,
                gridLineWidth: 1,
                gridLineColor: '#9a9194',
                lineColor:null, 
                categories: [],
                labels: {
                    useHTML: true,
                    allowOverlap: true,
                    style: {
                        //wordBreak: 'break-all',
                        textOverflow: 'allow'
                    }, 
                    events: {                    
                        click: function () {
                            let tempAgency = [];
                            this.axis.series.forEach((x) => {
                                if(x.yData[this.pos] > 0){
                                    tempAgency.push(x.userOptions.agency_model)
                                }
                            })
                            const tempCategories = this.axis.categories[this.pos]
                            console.log(tempCategories)
                            console.log(tempAgency)
                            openModal(tempCategories, tempAgency); 
                        }
                    },       
                    formatter: function () {
                        return '<div class="text-center font-oxygen" style="padding-bottom:11px;;text-align: center;">'+ t(this.value) + '</div>';
                    },
                }, 
            }],
            yAxis: {
                min: 0,
                gridLineWidth: 0,
                title: { text: null },
            },
            tooltip: {
                formatter: function () {
                     return "</table>" + this.points.reduce(function (s, point) {
                        return s + '<tr><td style="color:'+point.series.color+';padding:0">' + point.series.name + ': </td><td style="padding:0"><b>' + point.y + '</b></td></tr>';
                    }, '<b>' + t(this.x) + '</b><table>'); 
                },
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    groupPadding: 0.01
                },
                series: {
                    point: {
                        events: {    
                            click: function () { 
                                openModal(this.category, [this.series.userOptions.agency_model]); 
                            }
                        }  
                    }
                },
            },
            series: [],
        })


        const store = useStore()
        const data_chart = computed(() => store.state.result.limitation_landscape )          
        const loadChart = computed(() => store.state.result.load_chart2);  
        const setDataToChart = () => {
            if(data_chart.value && data_chart.value.categories1){                
                chartOptions.xAxis[0].categories = data_chart.value.categories0 
                chartOptions.xAxis[1].categories = data_chart.value.categories1
                chartOptions.series = data_chart.value.series
            }
        }
        watch(data_chart, ()  => { 
            setDataToChart()     
        })         
        onMounted(() => {
            setDataToChart()
        })


        //---------------------------- filter therapeutic_area ----------------------
        const list_therapeutic_area = computed(() => store.state.result.base_case_option.therapeutic_area);        
        const filter_therapeutic_area = computed({
            get () { return store.state.result.limitation_landscape_filter.therapeutic_area },
            set (value) { store.commit('result/setFilterLimitationLandscapeTherapeuticArea', value) }
        }); 


        //-------------------------- filter agency ----------------------
        const list_agency = computed(() => store.state.result.limitation_landscape_option_agency);
        const filter_agency = computed({
            get () { return store.state.result.limitation_landscape_filter.agency },
            set (value) { store.commit('result/setFilterLimitationLandscapeAgency', value) }
        }); 


        //-------------------------- filter name ----------------------
        const list_name = computed(() => store.state.result.base_case_option.name);
        const filter_name = computed({
            get () { return store.state.result.limitation_landscape_filter.name },
            set (value) { store.commit('result/setFilterLimitationLandscapeName', value) }
        }); 


        //---------------------------- filter limitation -------------------------------
        const list_limitation = [
            'limitations_population',
            'limitations_measurement_of_long_term_outcome',
            'limitations_measurement_of_utility', 
            'limitations_measurement_of_cost',
            'limitations_uncertainty_of_clinical_parameters',
            'limitations_uncertainty_of_cost_effectiveness_outcomes',
            'limitations_model_design',
            'limitations_comparators',
            'limitations_assumptions',
            'limitations_price'
        ];
        const filter_limitation = computed({
            get () { return store.state.result.limitation_landscape_filter.limitation },
            set (value) { store.commit('result/setFilterLimitationLandscapeLimitation', value) }
        }); 


        
        //-------------------------- handle click filter --------------------------[start]
        let dataFilterForSubChart =  ref({})
        const handleFilter = () => {            
           
            let objFilter = {};
            const therapeutic_area_all = filter_therapeutic_area.value.match(/^All /g);
            if(filter_therapeutic_area.value && !therapeutic_area_all){
                objFilter['filter_therapeutic_area'] = filter_therapeutic_area.value
            }
            if(filter_agency.value && filter_agency.value.length){
                objFilter['filter_limitation_landscape_agency'] = filter_agency.value
            }
            if(filter_name.value && filter_name.value.length){
                objFilter['filter_name'] = filter_name.value
            }
            if(filter_limitation.value && filter_limitation.value.length){
                objFilter['filter_limitation_landscape_limitation'] = filter_limitation.value
            }
            
            dataFilterForSubChart.value = {...objFilter};
            dataFilterForSubChart.value['filter_therapeutic_area'] = filter_therapeutic_area.value           
            console.log(objFilter)
            emit('request_data', objFilter)
            emit('request_result_chart_limitation_landscape', objFilter)  //request chart
        }
        //-------------------------- handle click filter  --------------------------[end]


        //----------------- Modal limitation -------------------[start]
        let modal = null;
        const data_table = computed(() => store.state.result.limitation_landscape_table )  
        const modal_data = ref([])  
        const modal_key = ref("")  
        onMounted(() => {
            modal = new Modal(document.getElementById('modal-limitation'));            
        });
        const openModal = (category, list_agency_model) => {
            //console.log(category+"  -  "+agency_model)
            modal_key.value = category
            let tempData = [];
            list_agency_model.forEach((agency_model) => {
                const temp = data_table.value.filter(i => i.agency_model == agency_model);
                tempData.push(temp)
            })
            
            modal_data.value = tempData
            modal.show();
        }
        //----------------- Modal limitation -------------------[end]


        //--------------------- Show Modal limitation landscape by country ------------------------[start]
       
        let modal2 = null;  
        onMounted(() => {
            modal2 = new Modal(document.getElementById('modal-limitation-main-country'));            
        });         
        const dataSubchart = ref([]);
        const showModalLimitationLandscapeCountry = (agency_model) => {
            dataSubchart.value = data_table.value.filter(i => i.agency_model == agency_model);
            modal2.show();
        }
        //--------------------- Show chart limitation landscape by country ------------------------[end]


        return {handleFilter, chartOptions, list_agency, filter_agency, list_name, filter_name, filter_limitation, list_limitation,
        list_therapeutic_area, filter_therapeutic_area, dataFilterForSubChart, modal_data, modal_key, dataSubchart, loadChart }
    }
}
</script>
