<template>
    <div class="row row-ct mb-2">
        <div class="col-12 col-md-2 pe-0">
            <div class="d-flex box-list">
                <div class="col">
                    <strong>{{$t('drug_name')}}</strong>
                    <span  @click="name.push('')" class="box-label cursor-pointer" ><font-awesome-icon icon="plus"  /></span>
                </div>
            </div>
            <div class="box-bg-gray">
                <div class="min85" ref="openBoxName" :class="!openBox.name ? 'wrap-box-list' : ''" >
                    <div class="box-wrapper-col">
                        <div v-for="(val, key) in name" :key="key" class="list-bg-white py-0 px-1" >
                            <div class="input-text">
                                <Autocomplete @onSet="openBox.name = true" page="result" :items="list_name" inputname="name" v-model="name[key]"  :placeholder="$t('add') + ' ' + $t('drug_name')" classinputname="form-control-sm w-100" :isLoading="autocomplete_load" />   
                            </div>
                            <div v-if="key === (name.length-1)" class="cursor-pointer" @click="name.push('')"><span class="text-info"><font-awesome-icon icon="plus" /></span></div>
                            <div v-else @click="name.splice(key,  1)" class="cursor-pointer"><span class="text-danger" ><font-awesome-icon icon="times" /></span></div>
                        </div>                        
                    </div>
                </div>
                <div class="box-tab-arrow bt-gray cursor-pointer" @click="togleInputBox('name')">
                    <font-awesome-icon :icon="openBox.name ? 'chevron-up' : 'chevron-down'" />
                </div>                
            </div>
        </div>
        <div class="col-12 col-md-3 pe-0">
            <div class="d-flex box-list">
                <div class="col">
                    <strong>{{$t('dci')}}</strong>
                    <span class="box-label cursor-pointer" @click="dci.push('')"><font-awesome-icon icon="plus" /></span>
                </div>
            </div>
            <div class="box-bg-gray">
                <div class="min85" ref="openBoxDci" :class="!openBox.dci ? 'wrap-box-list' : ''" >
                    <div class="box-wrapper-col">
                        <div v-for="(val, key) in dci" :key="key" class="list-bg-white py-0 px-1" >
                            <div class="input-text">
                                <Autocomplete @onSet="openBox.dci = true" page="result" :items="list_dci" inputname="dci" v-model="dci[key]"  :placeholder="$t('add') + ' ' + $t('dci')" classinputname="form-control-sm w-100" :isLoading="autocomplete_load" :parent="true"/>   
                            </div>
                            <div v-if="key === (dci.length-1)" class="cursor-pointer" @click="dci.push('')"><span class="text-info"><font-awesome-icon icon="plus" /></span></div>
                            <div v-else @click="dci.splice(key,  1)" class="cursor-pointer"><span class="text-danger" ><font-awesome-icon icon="times" /></span></div>
                        </div>                        
                    </div>
                </div>
                <div class="box-tab-arrow bt-gray cursor-pointer" @click="togleInputBox('dci')">
                    <font-awesome-icon :icon="openBox.dci ? 'chevron-up' : 'chevron-down'" />
                </div>
            </div>
        </div>
        <div class="col-12 col-md-3 pe-0">
            <div class="d-flex box-list">
                <div class="col">
                    <strong>{{$t('therapeutic')}}</strong>
                    <span class="box-label cursor-pointer" data-bs-toggle="modal" data-bs-target="#Modal-therapeutic" :style="{ padding: '0px 6px'}">List</span> <span class="box-label cursor-pointer" @click="therapeutic.push('')" :style="{ marginLeft:'48px'}"><font-awesome-icon icon="plus" /></span>
                </div>
            </div>
            <div class="box-bg-gray">
                <div class="min85" ref="openBoxTherapeutic" :class="!openBox.therapeutic ? 'wrap-box-list' : ''" >
                    <div class="box-wrapper-col">
                        <div v-for="(val, key) in therapeutic" :key="key" class="list-bg-white py-0 px-1" >
                            <div class="input-text">
                                <Autocomplete @onSet="openBox.therapeutic = true" page="result" :items="list_therapeutic" inputname="therapeutic" v-model="therapeutic[key]"  :placeholder="$t('add') + ' ' + $t('therapeutic')" classinputname="form-control-sm w-100" :isLoading="autocomplete_load" :parent="true"/>   
                            </div>
                            <div v-if="key === (therapeutic.length-1)" class="cursor-pointer" @click="therapeutic.push('')"><span class="text-info"><font-awesome-icon icon="plus" /></span></div>
                            <div v-else @click="therapeutic.splice(key,  1)" class="cursor-pointer"><span class="text-danger" ><font-awesome-icon icon="times" /></span></div>
                        </div>                        
                    </div>
                </div>
                <div class="box-tab-arrow bt-gray cursor-pointer" @click="togleInputBox('therapeutic')">
                    <font-awesome-icon :icon="openBox.therapeutic ? 'chevron-up' : 'chevron-down'" />
                </div>                
            </div>
        </div>
        <div class="col-12 col-md-2 pe-0">
            <div class="d-flex box-list">
                <div class="col">
                    <strong>Agency</strong>
                    <span class="box-label cursor-pointer" @click="handleAllAgency(true)" :style="{ padding: '0px 6px'}" >All</span> <span class="box-label cursor-pointer" @click="handleAllAgency(false)" :style="{ padding: '0px 6px', marginLeft:'48px'}" >Clear</span>
                </div>
            </div>
            <div class="box-flag-group main-overlay-np" >
                <template v-if="agency.length">
                    <div v-for="(item,key) in agency" :key="key" class="box-list-flag" style="margin-bottom: 2px;" @click="agency[key]['state'] = !agency[key]['state']">
                        <div class="list-child cursor-pointer">
                            <div class="flag-cover-sm" :class="{ active: item.state}" :title="item.acronym"><em class="flag sizesm" :class="item.icon_flags"></em></div>                        
                        </div>
                    </div>
                </template>
                <div v-else style="height:112px">
                    <div class="overlay">
                        <span class="multiselect-spinner" style="top: 37%;left: 43%;"></span>
                    </div> 
                </div>
            </div>
        </div>
        <div class="col-12 col-md-2 text-end btn-top-form">
            <button class="btn btn-sea-blue w-100" @click="handleSearch(true)">Search <font-awesome-icon icon="chevron-right"  :style="{ marginLeft:'6px' }" /></button>
            <button type="button" class="btn btn-White-gray w-100" data-bs-toggle="modal" data-bs-target="#modalConfirmClearAll">Clear all</button>
            <div class="dropdown dropdown-export main-overlay-np load-excel-overlay">
                <button class="btn btn-White-gray w-100" id="dropdownExport" data-bs-toggle="dropdown" aria-expanded="false">Export <font-awesome-icon icon="chevron-down" :style="{ marginLeft:'6px' }"/>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownExport">
                    <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modal-export">EXCEL</a></li>
                    <li><a class="dropdown-item" href="#">CSV</a></li>
                </ul>
                <div v-if="load_excel" class="overlay">
                    <div class="loadding_circle"></div>
                </div>
            </div>
        </div>
    </div>
    <ModalTherapeutic :items="list_therapeutic" @save_item_popup="save_item_popup" :isLoading="autocomplete_load"/>
    
    <!-- Modal confirm clear all data-->
    <div class="modal fade" id="modalConfirmClearAll" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-blue">
                <div class="confirm-feedback">
                    <h2>Clear all.</h2>
                    <span>You will clear all. Are you sure?</span>
                    <div class="box-area">
                        <div class="box-btn-menu">
                            <button class="btn btn-outline-secondary btn-form mb-1"  data-bs-dismiss="modal"> {{ $t("Cancel") }}</button>
                            <button class="btn btn-sea-blue btn-form mb-1" @click="clearAll">{{ $t("Confirm") }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModalTherapeutic from '@/components/elements/ModalTherapeutic.vue'  
import Autocomplete from '@/components/elements/Autocomplete.vue' 
import { Base64 } from 'js-base64'
import useFormSearch from "@/hooks/useFormSearch.js"
import { useRoute, useRouter  } from 'vue-router';
import { onMounted, computed, watch, ref, reactive } from "vue";
import { useStore } from 'vuex'
import { Modal } from 'bootstrap'
export default {
    components:{
        ModalTherapeutic,
        Autocomplete
    },
    setup(){
        

        const route = useRoute()
        const router = useRouter() 
        
        const {name,
        therapeutic,
        dci,
        list_name, 
        list_therapeutic, 
        list_dci, 
        autocomplete_load, 
        agency, 
        getSelectAgency,
        save_item_popup,
        handleAllAgency,
        handleSearch} = useFormSearch.useFormSearch();

        const store = useStore()     
        const load_excel = computed(() => store.state.result.load_excel );

        /**
         * onMounted set form search from params.base64Search
         */
        onMounted(() => {
            setForm();
        })

        watch(agency, ()  => {
            setForm();     
        })

        const setForm = () => {
            const base64Search = Base64.decode(route.params.base64Search);
            const arrSearch = base64Search.split('|');

            arrSearch.forEach((item) => {
                const item2 = item.split('=');
                if(item2[0] && item2[1]){
                    const tempVal = item2[1].split('#')
                    if(item2[0] === "name"){
                        name.value = tempVal
                    }
                    if(item2[0] === "dci"){
                        dci.value = tempVal
                    }
                    if(item2[0] === "therapeutic"){
                        therapeutic.value = tempVal
                    }
                    if(item2[0] === "agency"){
                        tempVal.forEach((tempVal2) => {
                            agency.value.forEach((agency2,k) => {
                                if(agency2.agency_model == tempVal2){
                                    agency.value[k]['state'] = true;
                                }
                            })
                        })
                    }
                }            
            })  
        }
          
        const openBoxName = ref(null)
        const openBoxDci = ref(null)
        const openBoxTherapeutic = ref(null)
        const openBox = reactive({
            name : false, dci : false, therapeutic : false
        });

        const togleInputBox = (param) => {
            if(!openBox[param]){
                if((param === "name" && openBoxName.value.scrollHeight > 87)
                || (param === "dci" && openBoxDci.value.scrollHeight > 87)
                || (param === "therapeutic" && openBoxTherapeutic.value.scrollHeight > 87)
                ){                
                    openBox[param] = !openBox[param];                                
                }
            }else{
                openBox[param] = !openBox[param];
            }
            
            
        }

        let modal = null;
        onMounted(() => {
            modal = new Modal(document.getElementById('modalConfirmClearAll'));            
        })

        const clearAll = () => { 
            modal.hide();
            router.push("/");             
        }

        return { 
            openBox,
            name,
            therapeutic,
            dci,
            list_name, 
            list_therapeutic, 
            list_dci, 
            autocomplete_load, 
            agency, 
            getSelectAgency,
            save_item_popup,
            handleAllAgency,
            handleSearch,
            load_excel,
            clearAll,
            togleInputBox,
            openBoxName,
            openBoxDci,
            openBoxTherapeutic
        }
    }
}
</script>
