<template>
    <div class="box-bg-gray box-radius-1">
        <hr style=" height: 4px;margin: 0;" />
    </div>
    <div class="box-bg-gray box-radius-1 main-overlay" style="min-height: 51px;" >
        <div v-if="!load" >
            <div class="box-ui-tab mb-1 px-1">
                <div class="row row-ct">
                    
                    <div class="col-12 col-md-5 pe-0">
                        <div class="d-flex">
                            <div style="width: 215px;"> ICER landscape (base case)</div>
                            <div style="width: 85px;"> <span class="flag size20" :class="icon_flags" style="vertical-align: middle;"></span> {{acronym}}</div>
                            <div style="width: 50%;">
                                <select name="filter_therapeutic_area" v-model="filter_therapeutic_area" class="select-option btn btn-sm btn-sea-blue">                                
                                    <option v-for="(item, key) in list_therapeutic_area" :key="key">
                                        {{item}}
                                    </option>
                                </select>                           
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3 pe-0">
                        <div class="box-bg-lightgray" style=" padding: 5px 4px;">
                            <div class="d-flex">
                                <div class="me-1"><strong>Date</strong></div>
                                <div style="width: 89%;">
                                    <div class="box-wrapper-lines">
                                        <div class="subline" style="width: 60px;">{{ DDMMMYY(new Date(filterDateRange[0]*1000)) }}</div>
                                        <div style="width: 45%;padding: 0 5px;" class="subline">
                                            <Slider v-model="filterDateRange" :tooltips="false" :min="dateMin" :max="dateMax" :step="86400" class="slider-blue"/>
                                        </div>
                                        <div class="subline" style="width: 60px;">{{DDMMMYY(new Date(filterDateRange[1]*1000))}}</div>
                                    </div>                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="box-bg-lightgray">
                            <div class="d-flex w-100">
                                <div class="me-3"><strong>ICER range</strong></div>
                                <div style="width: 75%;">
                                    <div class="box-wrapper-lines">
                                        <div style="width: 15%;" class="subline">{{filterIcerRange[0]}}</div>
                                        <div style="width: 60%;padding: 0 5px;" class="subline">
                                            <Slider v-model="filterIcerRange" :tooltips="false" :min="icerMin" :max="icerMax" :step="1" class="slider-blue"/>
                                        </div>
                                        <div class="subline">{{filterIcerRange[1]}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row row-ct px-1">
                <div class="col-12 col-md-9 pe-0">                
                    <div class="bg-white main-graph">
                    
                        <div class="box-graph" style="overflow: auto;">
                            <highcharts v-if="chartOptions.xAxis.categories.length" :options="chartOptions"  :style="chartOptions.xAxis.categories.length > 9 ? 'width:' + chartOptions.xAxis.categories.length*100 + 'px' : '' "></highcharts>
                            <div v-else class="graph-nodata"> NO DATA</div>
                        </div>
                    </div>

                    <div class="box-list-legend">
                        <ul>
                            <li><span><img src="@/assets/images/icon-arrow-up.png" alt="" > Average ICER per country</span></li>
                            <li><span><img src="@/assets/images/icon-square-blue.png" alt="" > Total ICER range</span></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="mb-2"><strong>Treatment Line</strong></div>
                    <div class="wrap-box-line">
                        <label v-for="(item, key) in list_treatment_line" :key="key" class="list-bg-white box-bg-lightgray">
                            <div>{{item.label}}</div>
                            <div><input class="form-check-input" type="checkbox" :value="item.value" v-model="filter_treatment_line"></div>
                        </label>
                        
                        <div class="mb-2"><strong>Drugs</strong></div>
                        <label v-for="(item, key) in list_name" :key="key"  class="list-bg-white box-bg-lightgray">
                            <div>{{item}}</div>
                            <div><input class="form-check-input" type="checkbox" :value="item" v-model="filter_name"></div>
                        </label>
                    </div>
                    <div class="button-fixed">
                        <button class="btn btn-sea-blue btn-form" @click="handleFilter">Filter</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="load" class="overlay">
            <div class="loadding_circle"></div>
        </div>
    </div>
    <ModalBaseCase idModal="modal-basecase-country" />
</template>

<script>
import { computed, ref, watch, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { DDMMMYY } from '@/utils'
import moment from 'moment'
import { Chart } from "highcharts-vue";
import More from 'highcharts/highcharts-more';
import Highcharts from 'highcharts'
More(Highcharts) 
import { Modal } from 'bootstrap'
import ModalBaseCase from '@/components/result/ModalBaseCase.vue'

import Slider from '@vueform/slider'
import "@/assets/css/range.css";
export default {
    components : {
        highcharts: Chart,
        ModalBaseCase,
        Slider
    },    
    props: {
        filter_parent: {
            type: Object,
            required: true
        },
    },
    emits: ['request_data', 'request_result_chart_base_case_country'],     
    setup(props, { emit }) {

        const store = useStore()
        const load = computed(() => store.state.result.load_chart_sub )
        

        const chartOptions = reactive({
               
            credits: { enabled: false },
            exporting: { enabled: false },
            title: { text: null },
            subtitle: { text: null },
            legend: { enabled: false },
            xAxis: {
                scrollbar: {
//                    enabled: true,
                    barBackgroundColor: '#fff',
                    barBorderColor: '#0C9CD4',
                    barBorderRadius: 7,
                    barBorderWidth: 1,
                    buttonBackgroundColor: 'none',
                    buttonBorderWidth: 0,
                    buttonBorderRadius: 7,
                    trackBackgroundColor: 'none',
                    trackBorderWidth: 0,
                    trackBorderRadius: 8,
                    trackBorderColor: '#fff',
                },
                title: {
                    enabled: true,
                    text: null
                },
                categories: [], //put Data
                gridLineWidth: 1,
                lineColor: '#ddd',
                gridLineColor: '#ddd',
                tickWidth: 0,                
                labels: {
                    color: '#fff',                  
                    x: 0,
                    useHTML: true,
                    formatter: function () { 
                        return '<div class="text-center" ><div>' + data_chart.value.name[this.value]  +'</div><div>'+DDMMMYY(data_chart.value.date[this.value])+'</div></div>';
                    },                    
                },
                plotLines: [] //put data On load
            },
            yAxis: {
                min:0,
                title: { text: null },
                plotLines: [], //put data On load     
                minorTickInterval: 'auto',
            },
            tooltip: {  
                enabled: false,
            },
                    
            plotOptions: {                
                scatter: {
                    marker: {
                    symbol:'circle',
                    radius: 5,
                    states: {
                        hover: {
                        enabled: true,
                        lineColor: 'rgb(100,100,100)'
                        }
                    }
                    }
                },
                series: { 
                    point: {
                    events: {
                        click: function () {
                           openModal(this.category);
                        }
                    },
                    }
                },
                columnrange: {
                    pointWidth: 11                    
                },
            },
            series: [
            {
                name: 'Range',
                type: 'columnrange',
                color: '#0C9CDC',
                lineColor: '#0C9CDC',
                data: [], //put data
            },
            {
                name: 'Exceed', //>
                type: 'scatter',
                lineColor: 'transparent',
                showInLegend: false, 
                enableMouseTracking: false,
                data: [], //put data
                marker: {
                radius: 5,
                fillColor: '#0C9CDC',
                symbol: 'url(https://www.prismheor.com/img/prismheor/triangle.png)',
                states: {
                    hover: {
                    enabled: false
                    }
                }
                }
            },
            {
                name: 'Less', //<
                type: 'scatter',
                lineColor: 'transparent',
                showInLegend: false, 
                enableMouseTracking: false,
                data: [], //put data
                marker: {
                radius: 5,
                fillColor: '#0C9CDC',
                symbol: 'url(https://www.prismheor.com/img/prismheor/triangle-down.png)',
                states: {
                    hover: {
                    enabled: false
                    }
                }
                }
            },
            {
                name: 'Single',
                type: 'scatter',
                color: '#0CA0DC',
                lineColor: 'transparent',
                data: [], //put data
                //data: [[0,16311], [5,8200]],
                marker: {
                symbol: 'url(https://www.prismheor.com/img/prismheor/plot-scatter_orange.png)'
                }
            }, 
            {
                name: 'Average',
                type: 'line',
                color: '#ffc000',
                lineColor: 'transparent',
                //enableMouseTracking: false,        
                data: [], //put data
                marker: {
                symbol: 'triangle'
                },
                tooltip: {
                //enabled: false
                },
            },
            ]
        });

        const data_chart = computed(() => store.state.result.base_case_country)       
        const acronym = computed(() => store.state.result.base_case_country_option.acronym) 
        const agency_model = computed(() => store.state.result.base_case_country_option.agency_model)
        const icon_flags = computed(() => store.state.result.base_case_country_option.icon_flags)      
        /**
         *  set series to chartOptions
         */ 
        const setDataToChart = () => {
            if(data_chart.value && data_chart.value.categories){                
                chartOptions.xAxis.categories = data_chart.value.categories
                chartOptions.series[0].data = data_chart.value.range
                chartOptions.series[1].data = data_chart.value.exceed
                chartOptions.series[2].data = data_chart.value.less
                chartOptions.series[3].data = data_chart.value.single
            }
        }
        watch(data_chart, ()  => { 
            setDataToChart()     
        })
        onMounted(() => {
            setDataToChart()
        })



        /**
         * get ICER range (min, max) from vuex
         */
        const icerMin = computed(() => store.state.result.base_case_country_option.icer_min);
        const icerMax = computed(() => store.state.result.base_case_country_option.icer_max);
         
        /**
         * ICER range [v-model]
         */
        const filterIcerRange = computed({
            get () { return store.state.result.base_case_country_option.icer_range },
            set (value) {                  
                store.commit('result/setFilterIcerRangeCountry', value)               
            }
        });     
       



        /**
         * get date range (min, max) from vuex
         */
        const dateMin = computed(() => store.state.result.base_case_country_option.date_min);
        const dateMax = computed(() => store.state.result.base_case_country_option.date_max);
        /**
         * Date range min [v-model]
         */
        const filterDateRange = computed({
            get () { return store.state.result.base_case_country_option.date_range },
            set (value) {                  
                store.commit('result/setFilterDateRangeCountry', value)               
            }
        });


        /**
         * list therapeutic areae from vuex
         */
        const list_therapeutic_area = computed(() => store.state.result.base_case_country_option.therapeutic_area); 
        /**
         * filter therapeutic area [v-model]
         */ 
        const filter_therapeutic_area = ref("");     
        watch(list_therapeutic_area, ()  => { 
            if(list_therapeutic_area.value && list_therapeutic_area.value.length){
                filter_therapeutic_area.value = list_therapeutic_area.value[0]
            }
        })


        /**
         * get list drug name from vuex
         */
        const list_name = computed(() => store.state.result.base_case_country_option.name);
        /**
         * filter Drugs [v-model]
         */
        const filter_name = ref([]);


        /**
         * list treatment line
         */
        const list_treatment_line = ref([
            {value : 'First line', label : 'First line'},
            {value : 'Second line', label : 'Second line'},
            {value : 'Third line', label : 'Third line'},
            {value : 'Rescue line', label : 'Rescue line'},
            {value : 'Maintenance', label : 'Maintenance'},
            {value : 'adjuvant', label : 'adjuvant'},
            {value : 'neo-adjuvant', label : 'neo-adjuvant'},
            {value : 'n/a', label : 'N/A'}
        ]);
        /**
         * filter Treatment Line [v-model]
         */
        const filter_treatment_line = ref([]);


        
         /**
         * Onclick filter in ICER landscape (base case) by country graph
         */
        const handleFilter = () => {
            
            let objFilter = {};
            objFilter['filter_agency_country'] = agency_model.value
            const therapeutic_area_all = filter_therapeutic_area.value.match(/^All /g);
            if(!therapeutic_area_all){
                objFilter['filter_therapeutic_area_country'] = filter_therapeutic_area.value
            }
            if(filter_name.value && filter_name.value.length){
                objFilter['filter_name_country'] = filter_name.value
            }
            if(filter_treatment_line.value && filter_treatment_line.value.length){
                objFilter['filter_treatment_line_country'] = filter_treatment_line.value
            }
            if(filterIcerRange.value[0] !== icerMin.value || filterIcerRange.value[1] !== icerMax.value){
                objFilter['filter_icer_max_country'] = filterIcerRange.value[1]
                objFilter['filter_icer_min_country'] = filterIcerRange.value[0]
            }
            if(filterDateRange.value[0] !== dateMin.value || filterDateRange.value[1] !== dateMax.value[1]){
                objFilter['filter_date_max_country'] = moment(new Date(filterDateRange.value[1]*1000)).format('YYYY-MM-DD') 
                objFilter['filter_date_min_country'] = moment(new Date(filterDateRange.value[0]*1000)).format('YYYY-MM-DD') 
            }

            const finalFilter = { ...props.filter_parent, ...objFilter };            
            
            emit('request_result_chart_base_case_country', finalFilter) 
            emit('request_result_chart_base_case_country', {...finalFilter, table : true}) 
            emit('request_data', finalFilter) 
        }

        



        //----------------- Modal basecase -------------------[start]
        const data_table = computed(() => store.state.result.base_case_country_table)    
        const modal = ref(null);
        onMounted(() => {
            modal.value = new Modal(document.getElementById('modal-basecase-country'));            
        });
        /**
         * Clicking on the graph will show a pop-up.
         * @property {Integer} id 
         */
        const openModal = (id) => {
            store.commit('result/setModalBasecase', null);
            const temp = data_table.value.filter(i => i.economic_evaluation_id == id);
            store.commit('result/setModalBasecase', temp);
            setTimeout(() => modal.value.show() , 200);
        }
        //----------------- Modal basecase -------------------[end]

        return { handleFilter, load, icerMin, icerMax, filterIcerRange, dateMin, dateMax,
         filterDateRange, list_therapeutic_area, list_name, filter_therapeutic_area,
         list_treatment_line, filter_treatment_line, filter_name, DDMMMYY, chartOptions,
         icon_flags, acronym}
    }
}
</script>